import { HoverDiv, pluralise } from 'components/Alerts/SavedAlerts';
import TablePopover from '../TablePopover';
import ListTokens from 'components/Alerts/ListTokens/ListTokens';
import { searchOrigin as origin } from 'components/Search/Results/constants';

const FiltersApplied = ({
  parsedSearch,
  searchOrigin,
  tokens,
  parentRef,
  noTokensDisplay,
  excludeTokens,
}) => {
  return (
    <div>
      {parsedSearch &&
      tokens &&
      tokens.length &&
      tokens.length > 0 &&
      searchOrigin !== origin.tradeshow ? (
        <TablePopover
          color='#c8b3fb'
          content={
            <HoverDiv style={{ flexDirection: 'column' }}>
              {pluralise('Filter', tokens)} applied:
              <ListTokens
                filter={parsedSearch}
                inputView={searchOrigin}
                excludeTokens={excludeTokens}
              />
            </HoverDiv>
          }
          parentRef={parentRef}
        >
          <HoverDiv
            style={{
              border: '2px solid #c8b3fb',
              borderRadius: '25px',
              height: '25px',
              minWidth: '120px',
            }}
          >
            {tokens.length} {pluralise('filter', tokens)} applied
          </HoverDiv>
        </TablePopover>
      ) : (
        <div>{noTokensDisplay}</div>
      )}
    </div>
  );
};
export default FiltersApplied;
