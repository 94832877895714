import { useState } from 'react';

import {
  Button,
  ResearcherEmail,
  ResearcherJobTitle,
  ResearcherOrganisation,
  SparklesIcon,
} from 'components/common';

import { formatNumber } from 'utils/number';
import EmailExplanation from 'components/common/Researcher/EmailExplanation';
import AiMessage from 'components/AiMessage';
import { useAuth } from 'hooks';
import licenseType from 'models/licenseType';

import { IconText, DetailsContainer } from './styled';
import ResearcherAddress from './Address';

const Details = ({ researcher }) => {
  const { researcherProfile, tradeShows } = researcher || {};
  const { hasLicense } = useAuth();
  const hasPermissionToAI = hasLicense(
    [licenseType.academic, licenseType.industry],
    true
  );
  const [showAIMessage, setShowAIMessage] = useState(false);

  return (
    <DetailsContainer>
      <ResearcherJobTitle
        iconTextComponent={IconText}
        title={researcherProfile.jobRole}
        previousTitle={researcherProfile.previousJobRole}
        startDate={researcherProfile.jobRoleChangedDate}
      />
      <ResearcherOrganisation
        iconTextComponent={IconText}
        name={researcherProfile.organisationName}
        previousName={researcherProfile.previousOrganisationName}
        startDate={researcherProfile.organisationChangedDate}
        superOrganisationId={researcherProfile.superOrganisationId}
      />
      <IconText value={researcherProfile.city} icon='map' />
      <ResearcherAddress researcherProfile={researcherProfile} />
      <ResearcherEmail
        allowFind
        buttonSize='small'
        dataToPost={{
          sciLeadsResearcherId: researcherProfile.id,
          location: 'ResearcherProfile',
        }}
        iconTextComponent={IconText}
        email={researcherProfile.email}
        isEmailShared={researcherProfile.emailShared}
        isEmailPubliclySourced={researcherProfile.emailPubliclySourced}
        emailStatusCategory={researcherProfile.emailStatus}
        emailLastAlive={researcherProfile.emailActiveCheckDate}
        researcherInactiveUpdateDate={
          researcherProfile.researcherInactiveUpdateDate
        }
        style={{ display: 'inline-flex' }}
        explanationComponent={() => (
          <EmailExplanation
            email={researcherProfile.email}
            organisationCategoryGroup={
              researcherProfile.organisationCategoryGroup
            }
          />
        )}
      />
      <IconText icon='email' />
      {hasPermissionToAI && (
        <div>
          <Button
            type='primary'
            size='small'
            onClick={() => setShowAIMessage(true)}
            icon={<SparklesIcon style={{ marginRight: '6px' }} />}
            style={{
              width: '100%',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Draft Message with AI
          </Button>
        </div>
      )}
      <IconText value={researcherProfile.phone} icon='phone' />
      {researcherProfile.allTimeHIndexAverage > 0 && (
        <IconText
          value={
            <div>
              <span style={{ fontWeight: 500 }}>
                Research Impact (All Time Avg):{' '}
              </span>
              {formatNumber(researcherProfile.allTimeHIndexAverage)}
            </div>
          }
          icon='impactScore'
        />
      )}
      {researcherProfile.threeYearHIndexAverage > 0 && (
        <IconText
          value={
            <div>
              <span style={{ fontWeight: 500 }}>
                Research Impact (3 Year Avg):{' '}
              </span>
              {formatNumber(researcherProfile.threeYearHIndexAverage)}
            </div>
          }
          icon='impactScore'
        />
      )}
      {showAIMessage && (
        <AiMessage
          requestLocation='search'
          researcherId={researcherProfile.id}
          city={researcherProfile.organisationCity}
          researcherTradeshows={tradeShows.map(p => ({
            id: p.sciLeadsTradeShowId,
            name: p.name,
            date: p.date,
            endDate: p.endDate,
          }))}
          onClose={() => setShowAIMessage(false)}
        />
      )}
    </DetailsContainer>
  );
};

export default Details;
