import { useSelector, useDispatch } from 'react-redux';

import { useGet, useAuth } from 'hooks';
import { Radio, Pane } from 'components/common';
import {
  userAlerts,
  userFollowedOrganisations,
  userFollowedResearchers,
} from 'services/api';
import { setView } from 'actions/alerts';
import { getAlertsView } from 'selectors/alerts';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import licenseType from 'models/licenseType';
import useLog, { outcomes } from 'hooks/useLog';

import ResearcherAlerts from './ResearcherAlerts';
import SavedAlerts from './SavedAlerts';
import OrganisationAlerts from './OrganisationAlerts';
import { views, metricActions } from './constants';
import { getResearcherText } from 'utils/constants';

const Alerts = () => {
  const dispatch = useDispatch();
  const view = useSelector(getAlertsView);
  const { user, hasLicense } = useAuth();
  const canFollowOrganisations = useMemo(
    () => hasLicense([licenseType.industry, licenseType.academic], true),
    [hasLicense]
  );

  const [{ res: alerts, loading: alertsLoading }, getUserAlerts] = useGet({
    url: userAlerts,
    query: { UserId: user.userId },
    onMount: false,
  });

  const [
    { res: followedResearchers, loading: researchersLoading },
    getFollowedResearchers,
  ] = useGet({
    url: userFollowedResearchers,
    query: { UserId: user.userId },
    onMount: false,
  });

  const [
    { res: followedOrganisations, loading: organisationsLoading },
    getFollowedOrganisations,
  ] = useGet({
    url: userFollowedOrganisations,
    query: { UserId: user.userId },
    onMount: false,
  });

  const lastLoggedView = useRef(null);
  const postLog = useLog(null, null, null, false);
  const handleLog = useCallback(
    alertsToLog => {
      if (lastLoggedView.current !== view && alertsToLog) {
        postLog({
          description: JSON.stringify({
            numberOfAlerts: alertsToLog?.length || 0,
          }),
          action: metricActions[view],
          outcome: outcomes.success,
        });
        lastLoggedView.current = view;
      }
    },
    [postLog, view]
  );

  useEffect(() => {
    if (view === views.savedSearches) {
      if (!alerts && !alertsLoading) {
        getUserAlerts();
      }

      handleLog(alerts);
    }
  }, [alerts, alertsLoading, getUserAlerts, handleLog, view]);

  useEffect(() => {
    if (view === views.followedResearchers) {
      if (!followedResearchers && !researchersLoading) {
        getFollowedResearchers();
      }

      handleLog(followedResearchers);
    }
  }, [
    followedResearchers,
    getFollowedResearchers,
    handleLog,
    researchersLoading,
    view,
  ]);

  useEffect(() => {
    if (canFollowOrganisations && view === views.followedOrganisations) {
      if (!followedOrganisations && !organisationsLoading) {
        getFollowedOrganisations();
      }

      handleLog(followedOrganisations);
    }
  }, [
    canFollowOrganisations,
    followedOrganisations,
    getFollowedOrganisations,
    handleLog,
    organisationsLoading,
    view,
  ]);

  const Title = ({ view }) => {
    const handleChangeView = view => {
      dispatch(setView(view));
    };

    const viewOptions = useMemo(() => {
      const options = [
        { label: 'Saved Searches', value: views.savedSearches },
        {
          label: `Followed ${getResearcherText({
            isPlural: true,
            capitalize: true,
          })}`,
          value: views.followedResearchers,
        },
      ];

      if (canFollowOrganisations) {
        options.push({
          label: 'Followed Organisations',
          value: views.followedOrganisations,
        });
      }

      return options;
    }, []);

    return (
      <>
        <span style={{ marginRight: 15 }}>Searches</span>
        <Radio
          value={view}
          options={viewOptions}
          optionType='button'
          onChange={handleChangeView}
          style={{ fontWeight: 'normal' }}
          size='small'
          data-testid='radio-view-options'
        />
      </>
    );
  };

  return (
    <Pane title={<Title view={view} />}>
      {view === views.savedSearches ? (
        <SavedAlerts
          data={alerts ?? []}
          setData={getUserAlerts}
          loading={alertsLoading}
        />
      ) : view === views.followedResearchers ? (
        <ResearcherAlerts
          data={followedResearchers ?? []}
          getResearchersToFollow={getFollowedResearchers}
          loading={researchersLoading}
        />
      ) : (
        <OrganisationAlerts
          data={followedOrganisations ?? []}
          getOrganisationsToFollow={getFollowedOrganisations}
          loading={organisationsLoading}
        />
      )}
    </Pane>
  );
};

export default Alerts;
