import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatNumber } from 'utils/number';
import { getCreditsString } from './helpers';
import { CollapsiblePanel, Icon } from 'components/common';
import { colours } from 'utils/theme';

import Stats from './Stats';
import {
  hasFullLicense,
  isAcademicTrial,
  isIndustryTrial,
  noAcademicLicence,
  noIndustryLicence,
} from 'utils/helpers/license';

const Container = styled.div`
  background-color: #f8f8f8;
  color: ${props =>
    props.disabled ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 1)'};
  padding: 5px 15px 5px 15px;
  font-weight: 500;
`;

const Header = styled.div`
  display: flex;
  font-weight: 700;
  .emails {
    font-weight: 700;
  }
  .credits {
    font-weight: 700;
    font-size: 11px;
  }
  .right-aligned {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
  .expander {
    display: flex;
    align-items: center;
  }
  .chevron {
    color: inherit;
    cursor: 'pointer';
  }
`;

function Total({
  user,
  totalEmails,
  credits,
  showCredits,
  disabled,
  industryCreditsRequired,
  academicCreditsRequired,
  previouslyExportedEmails,
  academicCreditsUsed,
  industryCreditsUsed,
  uncategorisedEmails,
  excludePreviouslyExported,
  isAcademicUnlimited,
  isIndustryUnlimited,
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  if (!(totalEmails >= -1)) return null;
  return (
    <Container disabled={disabled}>
      <Header
        style={{
          marginBottom: '8px',
          marginTop: '8px',
        }}
      >
        <div className={'expander'}>
          <div>Total</div>
          <Icon
            rotating={true}
            up={!isCollapsed}
            icon={'chevronDown'}
            size='sm'
            color={colours.black}
            onClick={() => setIsCollapsed(!isCollapsed)}
            className='chevron'
          />
        </div>
        <div className='right-aligned'>
          <span className='emails'>{formatNumber(totalEmails)} emails</span>
          {showCredits && (
            <span>
              &nbsp;/&nbsp;
              <span className='credits'>
                {getCreditsString(credits)} credits
              </span>
            </span>
          )}
        </div>
      </Header>
      <CollapsiblePanel
        isCollapsed={isCollapsed}
        children={
          <Stats
            excludePreviouslyExported={excludePreviouslyExported}
            isOnCreditSystem={user.isOnCreditSystem}
            showAcademicCredits={user.showAcademicCredits}
            showIndustryCredits={user.showIndustryCredits}
            exportTotalEmails={totalEmails}
            industryEmails={industryCreditsRequired}
            industryCredits={industryCreditsRequired}
            academicEmails={academicCreditsRequired}
            academicCredits={academicCreditsRequired}
            uncategorisedEmails={uncategorisedEmails}
            previouslyExportedEmails={previouslyExportedEmails}
            previouslyExportedCredits={
              (academicCreditsUsed ?? 0) + (industryCreditsUsed ?? 0)
            }
            isAcademicTrial={isAcademicTrial(user)}
            isIndustryTrial={isIndustryTrial(user)}
            hasAcademicLicense={!noAcademicLicence(user)}
            hasIndustryLicense={!noIndustryLicence(user)}
            hasFullLicense={hasFullLicense(user)}
            disabled={disabled}
            totalCredits={credits}
            isAcademicUnlimited={isAcademicUnlimited}
            isIndustryUnlimited={isIndustryUnlimited}
          />
        }
      />
    </Container>
  );
}

Total.propTypes = {
  totalEmails: PropTypes.number,
  showCredits: PropTypes.bool,
  credits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
};

export default Total;
