import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAuth } from 'hooks';
import {
  getSelectedMembers,
  getAreSomeMembersSelected,
  getActiveList,
} from 'selectors/lists';
import licenseType from 'models/licenseType';

import getResearchersConfig from './researchersConfig';
import getOrganisationsConfig from './organisationsConfig';

import { views } from '../../constants';

const useConfig = (
  handleToggleAll,
  handleToggleMember,
  view = views.researchers
) => {
  const { user, hasLicense } = useAuth();
  const activeList = useSelector(getActiveList);
  const selectedMembers = useSelector(getSelectedMembers);
  const someSelected = useSelector(getAreSomeMembersSelected);
  const canVisitOrgPage = useMemo(
    () => hasLicense([licenseType.industry, licenseType.academic], true),
    [hasLicense]
  );

  const config = useMemo(() => {
    if (view === views.researchers) {
      return getResearchersConfig(
        activeList?.id,
        user,
        selectedMembers,
        someSelected,
        handleToggleAll,
        handleToggleMember,
        canVisitOrgPage
      );
    }

    return getOrganisationsConfig(
      activeList?.id,
      selectedMembers,
      someSelected,
      handleToggleAll,
      handleToggleMember
    );
  }, [
    activeList?.id,
    canVisitOrgPage,
    handleToggleAll,
    handleToggleMember,
    selectedMembers,
    someSelected,
    user,
    view,
  ]);

  return config;
};

export default useConfig;
