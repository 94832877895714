export const DocumentCategories = {
  publication: 'Publications',
  poster: 'Tradeshows',
  trial: 'ClinicalTrials',
  funding: 'Funding',
  profile: 'Profile',
};

/**
 * @param {DocumentCategories} category
 * @returns {string}
 */
export const getDocumentIdentifierField = category => {
  switch (category) {
    case DocumentCategories.publication:
      return 'citationId';
    case DocumentCategories.poster:
      return 'sciLeadsPosterId';
    case DocumentCategories.trial:
      return 'nctId';
    case DocumentCategories.funding:
      return 'sciLeadsAwardId';
    case DocumentCategories.profile:
      return 'sciLeadsSuperResearcherId';
    default:
      return null;
  }
};
