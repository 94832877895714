import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import { v7 as uuidv7 } from 'uuid';
import { createGlobalStyle } from 'styled-components';
import { Button, Tooltip } from 'components/common';
import Summary from './Summary';
import { downloadType } from './Summary/constants';

const GlobalStyle = createGlobalStyle`
  .export-summary:hover {
    background-color: initial;
    cursor: initial;
  }
`;

function Export({
  onExport,
  disabled,
  downloadType,
  getQueryBody,
  children,
  ewrca,
  size = 'small',
  style = {},
  canUseCredits = true,
  ewrcaTooltip,
}) {
  const [open, setOpen] = useState(false);
  const [transactionId, setTransactionId] = useState(uuidv7());
  useEffect(() => {
    if (!disabled) {
      setTransactionId(uuidv7());
    }
  }, [disabled]);

  const regenerateTransactionId = useCallback(() => {
    setTransactionId(uuidv7());
  }, []);

  const items = useMemo(
    () =>
      !open
        ? []
        : [
            {
              key: 1,
              label: (
                <Summary
                  transactionId={transactionId}
                  regenerateTransactionId={regenerateTransactionId}
                  downloadType={downloadType}
                  onClose={() => setOpen(false)}
                  getQueryBody={getQueryBody}
                  onExport={onExport}
                  canUseCredits={canUseCredits}
                />
              ),
              className: 'export-summary',
              style: {
                padding: '15px',
                color: '#000000',
                backgroundColor: 'white',
              },
            },
          ],
    [
      downloadType,
      transactionId,
      getQueryBody,
      onExport,
      regenerateTransactionId,
      open,
      canUseCredits,
    ]
  );

  const handleOpenChange = nextOpen => {
    setOpen(nextOpen);

    if (nextOpen) {
      regenerateTransactionId();
    }
  };

  return (
    <Tooltip title={ewrca ? ewrcaTooltip : ''}>
      <GlobalStyle />
      <Dropdown
        disabled={disabled}
        menu={{
          items,
          onClick: () => null,
          onKeyDown: e => {
            if (e.key !== 'Escape') {
              e.stopPropagation();
            }
          },
        }}
        onOpenChange={handleOpenChange}
        open={open}
        destroyPopupOnHide
        trigger={['click']}
      >
        <Button style={style} size={size}>
          {children ? children : 'Export'}
        </Button>
      </Dropdown>
    </Tooltip>
  );
}

Export.propTypes = {
  onExport: PropTypes.func,
  downloadType: PropTypes.oneOf(
    Object.keys(downloadType).map(k => downloadType[k])
  ),
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  getQueryBody: PropTypes.func,
  ewrca: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default Export;
