import { setFilters as calendarSetFilters } from 'actions/calendar';
import { setFilters as purchasingSetFilters } from 'actions/purchasing';
import { setFiltersFromAlert, setView } from 'actions/search';
import { views } from 'components/Lists/constants';
import { searchOrigin } from 'components/Search/Results/constants';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  setOrgWorkItemFiltersByStateGroups,
  setView as setOrgView,
  setOrgResearcheriltersByStateGroups,
  setOrgClinicalTrialsiltersByStateGroups,
} from 'actions/organisation';
import {
  ORGANISATIONCLINICALTRIALSVIEW,
  ORGANISATIONPEOPLEVIEW,
  ORGANISATIONWORKITEMVIEW,
} from 'components/Organisation/helpers';
import { downloadType } from 'components/Export/Summary/constants';
import { ActionButton } from 'components/ExportHistory/styled';
import { Icon } from 'components/common';

const SearchAgain = ({
  type,
  structuredSearch,
  organisationId,
  researcherId,
  researcherListId,
  showText = true,
  organisationListId,
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const HandleClick = () => {
    if (type === downloadType.tradeshowSearch) {
      dispatch(calendarSetFilters(structuredSearch));
      push('/calendar');
    }
    if (type === downloadType.researcherSearch) {
      dispatch(
        setFiltersFromAlert(searchOrigin.researcherSearch, structuredSearch)
      );
      dispatch(setView(views.researchers));
      push('/');
    }
    if (
      type === downloadType.organisationSearch ||
      type === downloadType.organisationOnlyExport
    ) {
      dispatch(
        setFiltersFromAlert(searchOrigin.organisationSearch, structuredSearch)
      );
      dispatch(setView(views.organisations));
      push('/');
    }
    if (type === downloadType.purchasingSearch) {
      dispatch(purchasingSetFilters(structuredSearch));
      push('/purchasing');
    }
    if (type === downloadType.researcherList || type === downloadType.crmPush) {
      push(`/lists/${researcherListId}`);
    }
    if (type === downloadType.organisationList) {
      push(`/lists/${organisationListId}?view=organisation`);
    }
    if (
      type === downloadType.organisationClinicalTrialsSearch ||
      type === downloadType.organisationResearchersSearch ||
      type === downloadType.organisationActivitySearch
    ) {
      if (organisationId) {
        if (type === downloadType.organisationClinicalTrialsSearch) {
          dispatch(
            setOrgClinicalTrialsiltersByStateGroups(
              organisationId,
              structuredSearch
            )
          );
          dispatch(setOrgView(ORGANISATIONCLINICALTRIALSVIEW));
        } else if (type === downloadType.organisationResearchersSearch) {
          dispatch(
            setOrgResearcheriltersByStateGroups(
              organisationId,
              structuredSearch
            )
          );
          dispatch(setOrgView(ORGANISATIONPEOPLEVIEW));
        } else {
          dispatch(
            setOrgWorkItemFiltersByStateGroups(organisationId, structuredSearch)
          );
          dispatch(setOrgView(ORGANISATIONWORKITEMVIEW));
        }
        let url = '/organisation/' + organisationId;
        push(url, { avoidReset: true });
      }
    }
    if (type === downloadType.network) {
      let url = '/researcher/' + researcherId;
      push(url, { overrideView: 'network' });
    }
  };
  return (
    <ActionButton autoWidth={!showText} size='small' onClick={HandleClick}>
      <span title='Search again'>
        {showText && 'Search again '}
        <Icon icon='keywords' size='sm' style={{ color: 'inherit' }}></Icon>
      </span>
    </ActionButton>
  );
};
export default SearchAgain;
