import React from 'react';
import AlertSuccessContent from './AlertSuccessContent';
import { notification } from 'antd';
import { views } from '../constants';
import { getResearcherText } from 'utils/constants';

const NotificationSuccessAssignOneResearcherToList = (
  callback,
  type = views.researchers
) => {
  notification.open({
    message: 'List Updated',
    description: (
      <AlertSuccessContent
        message={`The ${
          type === views.researchers ? getResearcherText() : 'Organisation'
        } was added to the list`}
      />
    ),
    placement: 'bottomLeft',
  });
  if (callback) callback();
};

export default NotificationSuccessAssignOneResearcherToList;
