import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { messageTypes, messageTypesConfig } from './config';
import { Icon } from 'components/common';
import { theme } from 'utils/theme';
import { Tooltip } from 'antd';
import { getResearcherText } from 'utils/constants';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;
const MessageType = styled.button`
  width: 145px;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #fff;
  border: 1px solid ${p => (p.selected ? '#1890FF' : p.theme.borderGrey)};
  border-radius: 4px;
  cursor: pointer;
  color: ${p => (p.selected ? '#161616' : '#595959')};
  .title {
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin: 5px 0 0 0;
    padding: 0 8px;
    line-height: 1.3;
    width: 100%;
  }
  &.disabled {
    background-color: #fafafa;
    border-color: #d9d9d9;
    color: ${p => (p.selected ? '#8D8D8D' : '#BEBEBE')};
  }
`;

function MessageTypes({
  value,
  onChange,
  tradeshows,
  city,
  loading,
  style = {},
}) {
  const handleChange = useCallback(
    id => {
      onChange(id);
    },
    [onChange]
  );

  return (
    <Container style={style}>
      {messageTypesConfig.map(m => {
        const isDisabled =
          loading ||
          (m.id === messageTypes.tradeshow && !tradeshows?.upcoming?.length) ||
          (m.id === messageTypes.meeting && !city) ||
          (m.id === messageTypes.pastTradeshow && !tradeshows?.past?.length);

        let content = null;
        if (!loading && isDisabled) {
          if (m.id === messageTypes.tradeshow) {
            content = `Option is disabled because the ${getResearcherText()} does not have any future tradeshows.`;
          } else if (m.id === messageTypes.meeting) {
            content = `Option is disabled because the ${getResearcherText()}'s city is not known.`;
          } else if (m.id === messageTypes.pastTradeshow) {
            content = `Option is disabled because the ${getResearcherText()} doesn't have any recent posters from the past 2 months.`;
          }
        }

        const messageType = (
          <MessageType
            key={m.id}
            selected={m.id === value}
            onClick={() => {
              if (!isDisabled) {
                handleChange(m.id);
              }
            }}
            className={`${isDisabled ? 'disabled' : ''}`}
          >
            <Icon
              icon={m.icon}
              color={
                m.id === value
                  ? !isDisabled
                    ? '#1890FF'
                    : '#BEBEBE'
                  : !isDisabled
                  ? theme.borderGrey
                  : '#F0F0F0'
              }
              size='lg'
            />
            <div className='title'>{m.title}</div>
          </MessageType>
        );

        return content ? (
          <Tooltip key={m.id} title={content} placement='top'>
            {messageType}
          </Tooltip>
        ) : (
          messageType
        );
      })}
    </Container>
  );
}

MessageTypes.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  tradeshows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    })
  ),
  city: PropTypes.string,
};

export default MessageTypes;
