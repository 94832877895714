import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Loader, Link, ResearcherEmail } from 'components/common';
import { useAuth, usePost } from 'hooks';
import { getPoster } from 'services/api';
import { format } from 'utils/date';
import { getResearcherText } from 'utils/constants';

import { Container, Row, Pane, Detail } from './styled';
import CTA from './CTA';

const Poster = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const [{ res: poster, loading }] = usePost({
    url: getPoster,
    body: { id },
    onMount: true,
  });

  useEffect(() => {
    if (poster) {
      const posterTitle = poster.title || 'No Title';
      const titleAsArray = posterTitle.split(' ');
      const firstThree = titleAsArray.slice(0, 3);
      const title =
        titleAsArray.length > 3 ? `${firstThree.join(' ')}...` : posterTitle;
      document.title = `${title} | SciLeads`;
    }
  }, [poster]);

  if (loading)
    return (
      <Container>
        <Row>
          <Pane>
            <Loader />
          </Pane>
        </Row>
        <Row>
          <Pane>
            <Loader />
          </Pane>
          <Pane>
            <Loader />
          </Pane>
        </Row>
      </Container>
    );

  if (poster.showCta) {
    return <CTA />;
  }
  return (
    <Container>
      <Row>
        <Pane title='Poster Details'>
          <Detail label='Title'>{poster.title}</Detail>
          <Detail label='Presenter'>
            {poster.hasOwnProperty('presenter')
              ? poster.presenter
                ? 'Yes'
                : 'No'
              : 'Not Available'}
          </Detail>
          {poster.abstractLink && (
            <Detail label='Abstract'>
              <Link style={{ fontSize: 14 }} to={poster.abstractLink} external>
                View Full Abstract
              </Link>
            </Detail>
          )}
          <div style={{ display: 'inline-flex' }}>
            <Detail style={{ marginRight: '15px' }} label='Date Added'>
              {format(poster.dateAdded)}
            </Detail>
            <Detail style={{ marginRight: '15px' }} label='Date Updated'>
              {format(poster.dateUpdated)}
            </Detail>
            <Detail style={{ marginRight: '15px' }} label='Session Type'>
              {poster.sessionType !== 'Uncategorised'
                ? poster.sessionType
                : '-'}
            </Detail>
          </div>
        </Pane>
      </Row>
      <Row>
        <Pane title='Session Details'>
          <Detail label='Tradeshow'>{poster.show}</Detail>
          <Detail label='Title'>{poster.sessionTitle}</Detail>
          <Detail label='Day'>{poster.sessionDay}</Detail>
          <Detail label='Time'>{poster.sessionTime}</Detail>
        </Pane>
        <Pane title={`${getResearcherText({ capitalize: true })} Details`}>
          <Detail label='Name'>
            <Link to={`/researcher/${poster.sciLeadsResearcherId}`}>
              {poster.firstName} {poster.lastName}
            </Link>
          </Detail>
          {!user.hideResearcherEmail && (
            <Detail label='Email'>
              <ResearcherEmail
                email={poster.email}
                emailStatusCategory={poster.emailStatus}
                hideIcon
              />
            </Detail>
          )}
        </Pane>
        <Pane title='Organisation Details'>
          <Detail label='Name'>{poster.organisation}</Detail>
          <Detail label='Address'>{poster.address}</Detail>
          <Detail label='City'>{poster.city}</Detail>
          <Detail label='State'>{poster.state}</Detail>
          <Detail label='Postcode'>{poster.postcode}</Detail>
          <Detail label='Country'>{poster.country}</Detail>
        </Pane>
      </Row>
    </Container>
  );
};

export default Poster;
