import { Fragment } from 'react';
import { Checkbox, Link, ResearcherEmail } from 'components/common';
import { format } from 'utils/date';
import { getResearcherText } from 'utils/constants';

import CRMStatus from './CRMStatus';
import { makeHttpsUrl } from 'utils/helpers/externalLink';
import SocialMediaLink from 'components/Researcher/Profile/SocialMediaLink';

const getConfig = (
  listId,
  user,
  selectedMembers,
  someSelected,
  handleToggleAll,
  handleToggleMember,
  canVisitOrgPage
) => {
  const config = {
    headers: [
      {
        testId: 'checkbox',
        label: (
          <Checkbox
            checked={selectedMembers.all}
            indeterminate={someSelected}
            onClick={handleToggleAll}
          />
        ),
        minWidth: 20,
      },
      {
        label: getResearcherText({ capitalize: true }),
        key: 'name',
        minWidth: 100,
        maxContentWidth: 250,
      },
      {
        label: 'Organisation',
        key: 'organisationname',
        minWidth: 150,
        maxContentWidth: 300,
      },
      { label: 'State', key: '', minWidth: 100 },
      { label: 'Country', key: '', minWidth: 100 },
      { label: 'Email', key: 'email', minWidth: 120, maxContentWidth: 250 },
      { label: 'Linkedin', key: '', minWidth: 50 },
      { label: 'Added On', key: 'dateAddedUtc', minWidth: 100 },
      { label: 'Added By', key: 'addedbyuser', minWidth: 100 },
      { label: 'CRM Status', key: 'crmstatus', minWidth: 100 },
    ],
    createRow: (r, i) => (
      <Fragment key={`${r.name}-${i}`}>
        {/* Checkbox */}
        <div>
          <Checkbox
            checked={
              !selectedMembers.exclude[r.researcherId] &&
              (selectedMembers.include[r.researcherId] || selectedMembers.all)
            }
            onClick={() => handleToggleMember(r.researcherId)}
          />
        </div>
        {/* Researcher */}
        <div>
          {!r.profileOnly && (
            <div>
              <Link to={`/researcher/${r.researcherId}`}>{r.name}</Link>
            </div>
          )}
          {r.profileOnly && (
            <div>
              <span>{r.name}</span>
            </div>
          )}
        </div>
        {/* Organisation */}
        <div>
          {r.organisationId > 0 && canVisitOrgPage && (
            <div>
              <Link to={`/organisation/${r.organisationId}`}>
                {r.organisationName}
              </Link>
            </div>
          )}
          {(r.organisationId === 0 || !canVisitOrgPage) && (
            <div>
              <span>{r.organisationName}</span>
            </div>
          )}
        </div>
        {/* State */}
        <div>{r.state}</div>
        {/* Country */}
        <div>{r.country}</div>
        {/* Email */}
        {!user.hideResearcherEmail && (
          <div>
            <ResearcherEmail
              email={r.email}
              emailStatusCategory={r.emailVerificationCategory}
              hideIcon
            />
          </div>
        )}
        {/* Linkedin */}
        <div>
          <SocialMediaLink
            network={{
              type: 'linkedin',
              displayName: 'LinkedIn',
            }}
            title={r.linkedIn ? 'View on LinkedIn' : 'Search for LinkedIn'}
            url={
              r.linkedIn
                ? makeHttpsUrl(r.linkedIn)
                : `http://www.google.com/search?q=${r.name} ${r.organisationName} site:linkedin.com`
            }
            isMuted={!r.linkedIn}
            logDescription={{
              entityType: 'Researcher',
              location: `Lists:${listId}`,
            }}
          />
        </div>
        {/* DateAdded */}
        <div>{format(r.dateAddedUtc)}</div>
        {/* AddedBy */}
        <div>{r.addedByUser || 'Deleted User'}</div>
        {/* CRMStatus */}
        <div>
          <CRMStatus crmPushDetails={r.crmPushDetails} />
        </div>
      </Fragment>
    ),
  };

  if (user.hideResearcherEmail)
    config.headers = config.headers.filter(p => p.key !== 'email');

  return config;
};

export default getConfig;
