import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useGet, usePost } from 'hooks';
import { Drawer, Tooltip } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import dayjs from 'dayjs';

import { getFormValues } from 'selectors/ai';
import {
  aiMessage,
  researcherTradeshows as researcherTradeshowsUrl,
  userHasKeywords,
} from 'services/api';
import {
  Button,
  Checkbox,
  Detail,
  Icon,
  Input,
  Loader,
  SparklesIcon,
} from 'components/common';

import MessageTypes from './MessageTypes';
import Keywords from './Keywords';
import { keywordTypes, messageTypes } from './config';
import TradeshowLocation from './TradeshowLocation';
import FullLoader from './FullLoader';
import Subject from './Subject';
import Title from './Title';
import { compare } from 'utils/date';
import Message from './Message';
import { saveFormValues } from 'actions/ai';
import DetailsTooltip from './DetailsTooltip';

const GlobalStyle = createGlobalStyle`
  body {
    .ai-message-copy .ant-notification-notice-message {
      display: none;
    }
    .ai-message-copy svg {
      color: #fff;
    }
    .ai-drawer {
      z-index: 2147483600;
    }
    .ant-tooltip {
      z-index: 2147483600;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  height: 100%;
  padding: 24px 24px 8px 24px;
  .checkbox span {
    font-size: 13px;
    color: #161616;
  }
  .button-loader .loader {
    margin: 7px 35px 0 0;
  }
`;

const NoteContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #8d8d8d;
  margin-top: auto;
`;

function AiMessage({
  researcherId,
  researcherTradeshows,
  requestTradeshows,
  city,
  requestLocation,
  onClose,
}) {
  const dispatch = useDispatch();
  const cacheMessage = useSelector(getFormValues);
  const [message, setMessage] = useState({
    type: messageTypes.generic,
    productName: '',
    productDescription: '', // Add this line
    locationAtTradeshow: '',
    tradeshowId: null,
    keywordType: keywordTypes.default,
    keywords: [],
    meetingTime: '',
    generateSubject: true,
    excludeOldWorkItems: true,
    ...cacheMessage,
  });

  const generatedMessageType = useRef();
  const [generatedMessages, setGeneratedMessages] = useState({});
  const [tradeshows, setTradeshows] = useState(null);
  const [{ res: hasKeywords, loading: keywordsLoading }] = useGet({
    url: userHasKeywords,
    onMount: true,
  });
  const [
    { res: tradeshowsResp, loading: tradeshowsLoading },
    fetchResearcherTradeshows,
  ] = useGet({
    url: researcherTradeshowsUrl(researcherId),
    onMount: false,
  });
  const loading = keywordsLoading || tradeshowsLoading;

  const [
    { res: generatedMessageResp, error, loading: generateMessageLoading },
    generateMessage,
  ] = usePost({
    url: aiMessage,
  });

  const onChange = useCallback((key, value) => {
    setMessage(m => ({ ...m, [key]: value }));
  }, []);

  useEffect(() => {
    if (generatedMessageResp) {
      setGeneratedMessages(x => ({
        ...x,
        [generatedMessageType.current]: generatedMessageResp,
      }));
    }
  }, [generatedMessageResp]);

  useEffect(() => {
    if (error) {
      setGeneratedMessages(x => ({
        ...x,
        [generatedMessageType.current]: {
          message:
            error.status === 412
              ? error.message
              : error.status === 503
              ? 'The service is currently busy. Please try again later.'
              : 'An error occurred generating a message. Please try again later.',
          isError: true,
        },
      }));
    }
  }, [error]);

  useEffect(() => {
    if (hasKeywords !== undefined && hasKeywords !== null && !hasKeywords) {
      onChange('keywordType', keywordTypes.custom);
    }
  }, [hasKeywords, onChange]);

  useEffect(() => {
    if (requestTradeshows) {
      fetchResearcherTradeshows();
    }
  }, [fetchResearcherTradeshows, requestTradeshows]);

  const updateTradeshows = useCallback(shows => {
    let allTradeshows = shows.reduce((acc, current) => {
      if (!acc.some(x => x.id === current.id)) {
        acc.push(current);
      }
      return acc;
    }, []);

    const currentDate = dayjs();

    const upcomingTradeshows = allTradeshows
      .filter(x => dayjs(x.endDate).isAfter(currentDate))
      .sort((a, b) => compare(a, b));

    const pastTradeshows = allTradeshows
      .filter(
        x =>
          dayjs(x.endDate).isBefore(currentDate) &&
          dayjs(x.endDate).isAfter(currentDate.subtract(2, 'month'))
      )
      .sort((a, b) => compare(b, a));

    setTradeshows({ upcoming: upcomingTradeshows, past: pastTradeshows });
  }, []);

  useEffect(() => {
    if (tradeshowsResp) {
      updateTradeshows(
        tradeshowsResp.map(t => ({
          id: t.sciLeadsTradeShowId,
          name: t.show,
          date: t.date,
          endDate: t.endDate,
        }))
      );
    }
  }, [tradeshowsResp, updateTradeshows]);

  useEffect(() => {
    if (!requestTradeshows && researcherTradeshows) {
      updateTradeshows(researcherTradeshows);
    }
  }, [requestTradeshows, researcherTradeshows, updateTradeshows]);

  const onClear = useCallback(() => {
    setMessage(m => ({
      ...m,
      productName: '',
      productDescription: '', // Add this line
      locationAtTradeshow: '',
      keywordType: keywordTypes.default,
      keywords: [],
      meetingTime: '',
      tradeshowId: tradeshows?.[0]?.id,
    }));
  }, [tradeshows]);

  const onSubmit = useCallback(() => {
    generatedMessageType.current = message.type;
    dispatch(
      saveFormValues({
        productName: message.productName,
        productDescription: message.productDescription, // Add this line
        keywordType: message.keywordType,
        keywords: message.keywords,
        excludeOldWorkItems: message.excludeOldWorkItems,
      })
    );

    // Prepare the message data
    const messageData = {
      requestLocation,
      researcherId,
      ...message,
      messageType: message.type,
      useCustomKeywords:
        message.keywordType === keywordTypes.custom ||
        message.keywordType === keywordTypes.custonAndDefault,
      useAccountKeywords:
        message.keywordType === keywordTypes.default ||
        message.keywordType === keywordTypes.custonAndDefault,
      keywords:
        message.keywordType !== keywordTypes.default ? message.keywords : [],
      excludeOldWorkItems: message.excludeOldWorkItems ?? true,
    };

    // Handle specific message types
    if (message.type === messageTypes.pastTradeshow) {
      messageData.messageType = messageTypes.tradeshow; // Use the same type as upcoming tradeshow
      messageData.isPastTradeshow = true; // Add a flag to indicate it's a past tradeshow
    } else if (message.type === messageTypes.meeting) {
      messageData.city = city; // Ensure the city is included for "Meet in researcher's city" type
    }

    generateMessage(messageData);
  }, [message, dispatch, generateMessage, requestLocation, researcherId, city]);

  const handleMessageTypeChange = useCallback(
    type => {
      onChange('type', type);
      if (type === messageTypes.tradeshow && tradeshows?.upcoming?.length) {
        onChange('tradeshowId', tradeshows.upcoming[0].id);
      } else if (
        type === messageTypes.pastTradeshow &&
        tradeshows?.past?.length
      ) {
        onChange('tradeshowId', tradeshows.past[0].id);
      } else {
        // Reset tradeshowId for other message types
        onChange('tradeshowId', null);
      }
    },
    [onChange, tradeshows]
  );

  return (
    <Drawer
      headerStyle={{ padding: '16px' }}
      bodyStyle={{ padding: 0 }}
      title={<Title />}
      footer={null}
      open={true}
      onClose={onClose}
      width={528}
      placement='right'
      className='ai-drawer'
    >
      <GlobalStyle />
      <Container onClick={e => e?.stopPropagation()}>
        <div style={{ color: '#595959' }}>
          Generate a message using our AI Assistant. Use it later to contact
          anyone on social platforms. Select one of four presets that suits your
          needs.
        </div>
        <MessageTypes
          value={message.type}
          onChange={handleMessageTypeChange}
          style={{ margin: '16px 0' }}
          loading={loading || generateMessageLoading}
          tradeshows={tradeshows}
          city={city}
        />
        {loading && <FullLoader style={{ marginTop: '19px' }} />}
        {!loading && (
          <>
            {(message.type === messageTypes.tradeshow ||
              message.type === messageTypes.pastTradeshow) && (
              <TradeshowLocation
                tradeshows={
                  message.type === messageTypes.tradeshow
                    ? tradeshows?.upcoming
                    : tradeshows?.past
                }
                onTradeshowChange={value => onChange('tradeshowId', value)}
                tradeshow={message.tradeshowId}
                location={
                  message.type === messageTypes.tradeshow
                    ? message.locationAtTradeshow
                    : null
                }
                onLocationChange={value =>
                  message.type === messageTypes.tradeshow
                    ? onChange('locationAtTradeshow', value)
                    : null
                }
                loading={loading || generateMessageLoading}
                style={{ marginBottom: '16px' }}
                showLocationInput={message.type === messageTypes.tradeshow}
              />
            )}
            <Detail
              label={
                <>
                  Product Name
                  <DetailsTooltip title='Maximum 50 characters' />
                </>
              }
              style={{ marginBottom: '16px' }}
            >
              <Input
                placeholder='Product Name'
                value={message.productName}
                onChange={value => onChange('productName', value)}
                disabled={loading || generateMessageLoading}
                maxLength={50}
              />
            </Detail>
            {/* <Detail
              label={
                <>
                  Product Description
                  <DetailsTooltip title='Maximum 500 characters' />
                </>
              }
              style={{ marginBottom: '16px' }}
            >
              <Input
                placeholder='Product Description'
                value={message.productDescription ?? ''}
                onChange={value => onChange('productDescription', value)}
                disabled={loading || generateMessageLoading}
                maxLength={500}
              />
            </Detail> */}
            {message.type === messageTypes.meeting && (
              <Detail
                style={{ marginBottom: '20px' }}
                label={
                  <>
                    Date Of Visit
                    <DetailsTooltip title='Maximum 50 characters' />
                  </>
                }
              >
                <Input
                  placeholder='Date Of Visit'
                  value={message.meetingTime}
                  disabled={loading || generateMessageLoading}
                  onChange={newDate => onChange('meetingTime', newDate)}
                  maxLength={50}
                />
              </Detail>
            )}
            <Detail style={{ marginBottom: '16px' }}>
              <Tooltip title='If you choose to prioritise activities from the past year, these activities will be prioritised over older activities that may have more keyword matches. For example, if an activity from the past year has 5 matches to your keyword(s) and an activity from 2 years ago has 30 matches, the activity with 5 matches would get prioritised.'>
                <Checkbox
                  checked={message.excludeOldWorkItems ?? true}
                  disabled={loading || generateMessageLoading}
                  onChange={value => onChange('excludeOldWorkItems', value)}
                  labelText='Prioritise activities from the past year'
                ></Checkbox>
              </Tooltip>
            </Detail>
            <Keywords
              value={message.keywords}
              hasKeywords={hasKeywords}
              onChange={value => onChange('keywords', value)}
              keywordType={message.keywordType}
              onKeywordTypeChange={value => onChange('keywordType', value)}
              loading={loading || generateMessageLoading}
              style={{ marginBottom: '16px' }}
            />
            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                size='middle'
                onClick={onClear}
                disabled={loading || generateMessageLoading}
              >
                Clear all
              </Button>
              {!generateMessageLoading ? (
                <Button
                  type='primary'
                  size='middle'
                  icon={
                    !generatedMessages[message.type] ? (
                      <SparklesIcon style={{ marginRight: '6px' }} />
                    ) : (
                      <Icon
                        size='sm'
                        icon='refresh'
                        style={{ marginRight: '6px' }}
                      />
                    )
                  }
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                  onClick={onSubmit}
                >
                  {!generatedMessages[message.type]
                    ? 'Generate message'
                    : 'Generate again'}
                </Button>
              ) : (
                <Loader className='button-loader' />
              )}
            </div>
            {message.generateSubject && (
              <Subject
                subject={generatedMessages[message.type]?.subject}
                style={{ marginBottom: '16px' }}
                loading={loading || generateMessageLoading}
                shouldStream={generatedMessageResp}
                disabled={
                  loading ||
                  generateMessageLoading ||
                  !generatedMessages[message.type]?.subject
                }
              />
            )}
            <Message
              value={generatedMessages[message.type]?.message}
              shouldStream={generatedMessageResp}
              isError={generatedMessages[message.type]?.isError}
              disabled={
                loading ||
                generateMessageLoading ||
                !generatedMessages[message.type]?.message
              }
              loading={generateMessageLoading}
            />
            <NoteContainer style={{ marginTop: '16px', paddingBottom: '20px' }}>
              AI relies on patterns and data inputs, so errors may occur.
            </NoteContainer>
          </>
        )}
      </Container>
    </Drawer>
  );
}

AiMessage.propTypes = {
  requestLocation: PropTypes.string.isRequired,
  researcherId: PropTypes.number.isRequired,
  city: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  researcherTradeshows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    })
  ),
  requestTradeshows: PropTypes.bool,
};

export default AiMessage;
