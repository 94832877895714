import dayjs from 'dayjs';
import { getResearcherText } from 'utils/constants';

export { default as Filter } from './FilterField';

export const helpText = {
  orgCategories:
    'This enables you to set the type of organisation but please note that currently only around 70% of data has received categorization',
  dateOfActivity: `This enables you to set a date on the ${getResearcherText({
    isPlural: true,
  })}' most recent relevant activity(based on your keywords)`,
  fundingStarts:
    'This data is available for 80 % of all grants in the platform',
  fundingEnds: 'This data is available for 80 % of all grants in the platform',
  openGrant:
    'A definitive start and end date is available for 80 % of all grants',
  filterDisabled: 'This field is not compatible with current active filters.',
  earlyAwardDate:
    'These are projects with budget already awarded but the funding/spending start date is in the future. This applies to NIH grants only.',
  grantExpiry: `Funding End allows you to find ${getResearcherText({
    isPlural: true,
  })} who may have funds left that need to be spent in the short term. As not all grants specify an end date this filter works on the approximately 60% of grants that do provide a funding ends date.`,
  thisShowOnly:
    'Selecting this option will only search the presentation information e.g., title and keywords from the chosen tradeshow(s) in the dropdown list',
  thisSessionTypeOnly: `Select this box and you will only search ${getResearcherText()}'s activity with the chosen session type(s) e.g. if you select keynote the search will only look for your keyword in keynote presentations and not the entire ${getResearcherText()}'s profile`,
  exhibitingShow:
    'Selecting this option will filter for all companies exhibiting at the tradeshow. If you have a keyword in the main search bar this will also search their entire profile of research activity e.g., publications, grants, clinical trials.',
  presentingShow:
    "Selecting this option will filter for all companies presenting at the tradeshow. If you have a keyword in the main search bar this will also search the presenting company's entire profile of research activity e.g., publications, grants, clinical trials. e.g., Search all companies presenting at AACR who work on CRISPR even if they didn't mention CRISPR in their AACR presentation.",
};

export const calculateDate = date => {
  let from, to;
  if (Array.isArray(date)) {
    from = date[0];
    to = date[1];
  } else {
    to = dayjs().toISOString();

    if (date === 'one') {
      from = dayjs()
        .subtract('1', 'month')
        .toISOString();
    }

    if (date === 'six') {
      from = dayjs()
        .subtract('6', 'month')
        .toISOString();
    }

    if (date === 'twelve') {
      from = dayjs()
        .subtract('1', 'year')
        .toISOString();
    }

    if (date === 'twentyfour') {
      from = dayjs()
        .subtract('2', 'year')
        .toISOString();
    }

    if (date === 'thirtysix') {
      from = dayjs()
        .subtract('3', 'year')
        .toISOString();
    }
  }

  return [from, to];
};

export const showTradeshowWarning = (from, to, tradeshows) => {
  return tradeshows?.some(
    t =>
      from &&
      to &&
      t.startDate &&
      (dayjs(t.startDate).isBefore(from) || dayjs(t.startDate).isAfter(to))
  );
};
