import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePost } from 'hooks';
import { useHistory } from 'react-router-dom';
import { organisationsLists, researchersLists } from 'services/api';

import {
  performSearchListAssignedToResearchers,
  performSearchListAssignedToOrganisations,
  setListAssignedToResearchers,
  setListAssignedToOrganisatinos,
  addMembersToListFromSearchError,
} from 'actions/search';
import { reloadUsers } from 'actions/users';
import DownloadNotification, {
  downloadGuidRegex,
} from 'components/NotificationWrapper/DownloadNotification';

import $hite from 'jquery';
import { notification } from 'antd';

import { useAuth } from './useAuth';
import { setSignalRExportUpdate } from 'actions/exportHistory';

window.jQuery = $hite;

require('signalr');

const OPERATION_ADDRESEARCHERLISTMEMBERSFROMSEARCH =
  'Operation: ADDRESEARCHERLISTMEMBERSFROMSEARCH';
const OPERATION_ADDORGANISATIONLISTMEMBERSFROMSEARCH =
  'Operation: ADDORGANISATIONLISTMEMBERSFROMSEARCH';
const OPERATION_ADDLISTMEMBERSFROMSEARCHFAILED = '"CurrentCount":';
const OPERATION_UPDATEEXPORTHISTORYSUCCESS = 'Success-ExportHistoryId:';
const OPERATION_UPDATEEXPORTHISTORYFAILED = 'Failed-ExportHistoryId:';
const OPERATION_FORCELOGOUT = 'Operation: FORCELOGOUT';
const OPERATION_DONOTDISMISS = 'Operation: DONTAUTODISMISS';

const useNotification = () => {
  const { user, logOut } = useAuth();
  const { replace } = useHistory();
  const connectionRef = useRef();
  const proxyRef = useRef();
  const [messageReceived, setMessageReceived] = useState(false);
  const [addOrgFromSearchReceived, setAddOrgFromSearchReceived] = useState(
    false
  );

  const dispatch = useDispatch();
  const [researcherListsState, postResearcherLists] = usePost({
    url: researchersLists,
  });
  const [organisationListsState, postOrganisationLists] = usePost({
    url: organisationsLists,
  });

  useEffect(() => {
    if (
      researcherListsState.res != null ||
      researcherListsState.error ||
      researcherListsState.loading
    ) {
      dispatch(setListAssignedToResearchers(researcherListsState));
    }
  }, [researcherListsState, dispatch]);

  useEffect(() => {
    if (messageReceived) {
      dispatch(performSearchListAssignedToResearchers(postResearcherLists));

      setMessageReceived(false);
    }
  }, [messageReceived, dispatch, postResearcherLists]);

  useEffect(() => {
    if (
      organisationListsState.res != null ||
      organisationListsState.error ||
      organisationListsState.loading
    ) {
      dispatch(setListAssignedToOrganisatinos(organisationListsState));
    }
  }, [organisationListsState, dispatch]);

  useEffect(() => {
    if (addOrgFromSearchReceived) {
      dispatch(performSearchListAssignedToOrganisations(postOrganisationLists));

      setAddOrgFromSearchReceived(false);
    }
  }, [addOrgFromSearchReceived, dispatch, postOrganisationLists]);

  useEffect(() => {
    if (
      user &&
      user.userEmail &&
      !connectionRef.current &&
      !process.env.REACT_APP_IS_CYPRESS
    ) {
      connectionRef.current = $hite.hubConnection(
        process.env.REACT_APP_SIGNALR_URL,
        {
          useDefaultPath: false,
          qs: { username: user.userEmail },
        }
      );

      const startConnection = async () => {
        try {
          await connectionRef.current.start();
        } catch (e) {
          console.log(e);
        }
      };

      connectionRef.current.disconnected(() => {
        setTimeout(startConnection, 15000);
      });

      proxyRef.current = connectionRef.current.createHubProxy(
        'portalSocketHub'
      );

      const socketMessageReceived = (title, body, forceLogout) => {
        if (forceLogout && !body.includes(OPERATION_FORCELOGOUT)) logOut();
        let bodyAux = body;

        if (bodyAux.includes(OPERATION_FORCELOGOUT)) {
          const receivedGuid = bodyAux
            .replace(OPERATION_FORCELOGOUT, '')
            .trim();
          bodyAux =
            'Your account has logged in elsewhere.<br/>You will now be logged out.';
          const storedGuid = localStorage.getItem('SESSION_ID');

          if (
            forceLogout &&
            storedGuid &&
            receivedGuid &&
            receivedGuid !== storedGuid
          ) {
            logOut();
          }
        }

        if (bodyAux.includes(OPERATION_ADDRESEARCHERLISTMEMBERSFROMSEARCH)) {
          bodyAux = body.replace(
            OPERATION_ADDRESEARCHERLISTMEMBERSFROMSEARCH,
            ''
          );
        }

        if (bodyAux.includes(OPERATION_ADDORGANISATIONLISTMEMBERSFROMSEARCH)) {
          bodyAux = body.replace(
            OPERATION_ADDORGANISATIONLISTMEMBERSFROMSEARCH,
            ''
          );
        }

        if (bodyAux.includes(OPERATION_DONOTDISMISS)) {
          bodyAux = body.replace(OPERATION_DONOTDISMISS, '');
        }

        if (bodyAux.includes(OPERATION_UPDATEEXPORTHISTORYSUCCESS)) {
          bodyAux = body.replace(OPERATION_UPDATEEXPORTHISTORYSUCCESS, '');
          let exportHistoryId = parseInt(bodyAux);
          if (exportHistoryId > 0)
            dispatch(setSignalRExportUpdate(exportHistoryId));
          return;
        }

        if (bodyAux.includes(OPERATION_UPDATEEXPORTHISTORYFAILED)) {
          bodyAux = body.replace(OPERATION_UPDATEEXPORTHISTORYFAILED, '');
          let exportHistoryId = parseInt(bodyAux);
          if (exportHistoryId > 0)
            dispatch(setSignalRExportUpdate(exportHistoryId));
          return;
        }

        if (bodyAux.includes(OPERATION_ADDLISTMEMBERSFROMSEARCHFAILED)) {
          dispatch(addMembersToListFromSearchError(bodyAux));
          return;
        }

        const parsed = <div dangerouslySetInnerHTML={{ __html: bodyAux }} />;

        if (body.includes(OPERATION_DONOTDISMISS)) {
          notification.open({
            message: title,
            description: parsed,
            placement: 'bottomLeft',
            duration: 0,
          });
          setMessageReceived(true);
        } else if (
          body.includes(OPERATION_ADDRESEARCHERLISTMEMBERSFROMSEARCH)
        ) {
          notification.open({
            message: title,
            description: parsed,
            placement: 'bottomLeft',
          });
          setMessageReceived(true);
        } else if (
          body.includes(OPERATION_ADDORGANISATIONLISTMEMBERSFROMSEARCH)
        ) {
          notification.open({
            message: title,
            description: parsed,
            placement: 'bottomLeft',
          });
          setAddOrgFromSearchReceived(true);
          // if it's a download link
        } else if (bodyAux.match(downloadGuidRegex)) {
          const notificationKey = 'download-notification';
          notification.open({
            key: notificationKey,
            message: title,
            description: (
              <DownloadNotification
                html={bodyAux}
                replace={replace}
                notificationKey={notificationKey}
              />
            ),
            placement: 'bottomLeft',
            duration: 6,
          });
        } else {
          notification.open({
            message: title,
            description: parsed,
            placement: 'bottomLeft',
            duration: 6,
          });
          if (title === 'User Created') {
            dispatch(reloadUsers(true));
          }
        }
      };

      proxyRef.current.on('socketMessageReceived', socketMessageReceived);

      startConnection();
    }
  }, [user, logOut, dispatch, replace]);

  useEffect(() => {
    if (!user && connectionRef.current) {
      proxyRef.current.off('socketMessageReceived');
      proxyRef.current = null;
      connectionRef.current = null;
    }
  }, [user]);
};

export default useNotification;
