import styled, { keyframes } from 'styled-components';
import { List as AntList } from 'antd';

const fadeIn = keyframes`
  from { 
    opacity: 0; 
    transform: translateY(-5px); 
  }
  to { 
    opacity: 1; 
    transform: translateY(0); 
  }
`;

export const Container = styled.div`
  width: ${props => props.width}px;
  min-height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  opacity: 0;
  animation: ${fadeIn} 0.2s ease forwards;
`;

export const List = styled(AntList)`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const ListItem = styled(AntList.Item)`
  opacity: 0;
  animation: ${fadeIn} 0.2s ease forwards;
  animation-delay: ${props => props.index * 0.05}s;
`;
