import { useState } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { Button, Input } from 'components/common';
import { salesEnquiry } from 'services/api';
import { usePost } from 'hooks';

import { FormContent } from './styled';
import { getResearcherText } from 'utils/constants';

const FromSalesforce = () => {
  const { search } = useLocation();
  const { from } = qs.parse(search);
  const [{ res, loading, error }, postEnquiry] = usePost({ url: salesEnquiry });
  const [enquiryForm, setEnquiryForm] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const handleEnquiryChange = key => value => {
    setEnquiryForm(s => ({
      ...s,
      [key]: value,
    }));
  };

  const handleEnquiry = () => {
    postEnquiry({
      ...enquiryForm,
      message: `User tried to access a ${getResearcherText()} via Salesforce but has no account and has requested a trial.`,
    });
  };

  if (from !== 'sf') return null;

  const noDetails = !enquiryForm.name || !enquiryForm.email;

  let content = (
    <>
      <Input
        placeholder='Name'
        onChange={handleEnquiryChange('name')}
        value={enquiryForm.name}
        disabled={loading}
      />
      <Input
        placeholder='Email'
        onChange={handleEnquiryChange('email')}
        value={enquiryForm.email}
        disabled={loading}
      />
      <Input
        placeholder='Phone Number'
        onChange={handleEnquiryChange('phone')}
        value={enquiryForm.phone}
        disabled={loading}
      />
      <Button
        type='primary'
        onClick={handleEnquiry}
        disabled={loading || noDetails}
      >
        Request Trial
      </Button>
    </>
  );

  if (res) {
    content = (
      <div style={{ textAlign: 'center', marginTop: 25 }}>
        Thank you for your enquiry, our team will be in touch soon.
      </div>
    );
  }

  if (error) {
    content = (
      <div style={{ textAlign: 'center', marginTop: 25 }}>
        Something went wrong while processing your request, please reach out to
        us via the live chat in the bottom right.
      </div>
    );
  }

  return (
    <FormContent>
      <span>No Account?</span>
      {content}
    </FormContent>
  );
};

export default FromSalesforce;
