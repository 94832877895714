import { Input, Tooltip } from 'components/common';
import UserFilter from './UserFilter';
import {
  FilterItem,
  FiltersContainer,
  FiltersRow,
  HistorySelect,
  Switch,
} from '../styled';
import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import DateFilter from './DateFilter';
import { EXPORT_TYPE, StatusButtonConfig } from '../ExportHistoryHelper';
import { tagRender } from 'components/common/TagRender';

const statusOptions = Object.keys(StatusButtonConfig).map(key => ({
  label: StatusButtonConfig[key].text,
  value: key,
}));

export default function ExportHistoryFilters({
  filters,
  user,
  updateFilters,
  fullWidth,
}) {
  const [searchFilter, setSearchFilter] = useState(filters.searchFilter);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    debounce(value => {
      updateFilters({ ...filters, searchFilter: value, pageNumber: 0 });
    }, 300),
    [filters, updateFilters]
  );

  function updateSearchFilter(value) {
    setSearchFilter(value);
    handleInputChange(value);
  }

  const handleUserSelectionChange = values => {
    updateFilters({ ...filters, userIds: values, pageNumber: 0 });
  };

  function setDontShowExpired(value) {
    updateFilters({ ...filters, dontShowExpired: value, pageNumber: 0 });
  }

  function updateDateFilter(values) {
    updateFilters({
      ...filters,
      startDate: values[0],
      endDate: values[1],
      pageNumber: 0,
    });
  }

  function updateType(value) {
    updateFilters({ ...filters, type: value, pageNumber: 0 });
  }

  function updateStatus(value) {
    updateFilters({ ...filters, status: value, pageNumber: 0 });
  }

  const searchFilterItem = (
    <FilterItem>
      <Tooltip placement={'right'} title={searchFilter ? 'Search' : ''}>
        <Input
          onChange={updateSearchFilter}
          placeholder='Search'
          value={searchFilter}
          allowClear
        />
      </Tooltip>
    </FilterItem>
  );

  const userFilterItem = (
    <>
      {user.isUserManager && (
        <FilterItem>
          <UserFilter
            onChange={handleUserSelectionChange}
            userIds={filters.userIds || []}
          />
        </FilterItem>
      )}
    </>
  );

  const typeFilterItem = (
    <FilterItem>
      <HistorySelect
        options={Object.values(EXPORT_TYPE)}
        placeholder={'Type'}
        mode={'multiple'}
        value={filters.type}
        allowClear={true}
        onChange={updateType}
        tagRender={tagRender}
      />
    </FilterItem>
  );

  const statusFilterItem = (
    <FilterItem>
      <HistorySelect
        options={statusOptions}
        placeholder={'Status'}
        mode={'multiple'}
        value={filters.status}
        allowClear={true}
        onChange={updateStatus}
        tagRender={tagRender}
      />
    </FilterItem>
  );

  const dateFilterItem = (
    <DateFilter
      onChange={updateDateFilter}
      userId={user.userId}
      initialDateRange={
        filters.startDate && filters.endDate
          ? [filters.startDate, filters.endDate]
          : []
      }
    />
  );
  const excludeExpiredFilterItem = (
    <FilterItem>
      <Switch
        checked={filters.dontShowExpired}
        onChange={setDontShowExpired}
        label={'Exclude expired exports'}
      />
    </FilterItem>
  );

  if (fullWidth)
    return (
      <FiltersContainer>
        <FiltersRow>
          {searchFilterItem}
          {userFilterItem}
          {typeFilterItem}
          {statusFilterItem}
          {dateFilterItem}
        </FiltersRow>
        <FiltersRow>{excludeExpiredFilterItem}</FiltersRow>
      </FiltersContainer>
    );

  return (
    <FiltersContainer>
      <FiltersRow>
        {searchFilterItem}
        {dateFilterItem}
        {excludeExpiredFilterItem}
      </FiltersRow>
      <FiltersRow>
        {userFilterItem}
        {typeFilterItem}
        {statusFilterItem}
      </FiltersRow>
    </FiltersContainer>
  );
}
