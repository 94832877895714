import { usePost } from 'hooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLastSuccessfulSearch } from 'selectors/search';
import { checkExportSearch } from 'services/api';
import { downloadType as downloadTypeConstants } from './constants';

const isSearchPage = type =>
  [
    downloadTypeConstants.researcherSearch,
    downloadTypeConstants.organisationSearch,
  ].includes(type);
const isResearcherSearch = type =>
  type === downloadTypeConstants.researcherSearch;
const getBody = (type, transactionId, lastSuccessfulSearch, getQuerybody) => {
  if (isSearchPage(type)) {
    return {
      transactionId,
      query: lastSuccessfulSearch?.query,
      filters: {
        lists: lastSuccessfulSearch?.lists,
        excludeLists: lastSuccessfulSearch?.excludeLists,
        organisationLists: lastSuccessfulSearch?.organisationLists,
        organisationExcludeLists:
          lastSuccessfulSearch?.organisationExcludeLists,
        excludeRecentlyExportedEmails:
          isResearcherSearch(type) &&
          lastSuccessfulSearch?.excludeRecentlyExportedEmails,
      },
      nestedQueries: lastSuccessfulSearch?.nestedQueries,
      downloadType: type,
    };
  }

  return {
    ...(getQuerybody ? getQuerybody() : {}),
    transactionId,
    downloadType: type,
  };
};

function useCheckExport(
  transactionId,
  downloadType,
  getQueryBody,
  disabledExport,
  canUseCredits
) {
  // search page only
  const lastSuccessfulSearch = useSelector(getLastSuccessfulSearch);
  const [
    { res: checkExportRes, error, loading: checkExportLoading },
    checkExport,
  ] = usePost({
    url: checkExportSearch,
  });

  useEffect(() => {
    if (disabledExport || !canUseCredits) {
      return;
    }

    const body = getBody(
      downloadType,
      transactionId,
      lastSuccessfulSearch,
      getQueryBody
    );
    checkExport({
      body,
    });
  }, [
    checkExport,
    downloadType,
    getQueryBody,
    lastSuccessfulSearch,
    transactionId,
    disabledExport,
    canUseCredits,
  ]);

  return [checkExportRes, checkExportLoading, error];
}

export default useCheckExport;
