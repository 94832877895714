import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getLastSuccessfulSearch } from 'selectors/search';
import { Tag } from 'antd';
import styled from 'styled-components';

import { TabbedPanel, CollapsiblePanel, Avatar, Icon } from 'components/common';
import { colours } from 'utils/theme';
import { useWindowWidth } from 'hooks';

import Profile from './Profile';
import Actions from './Actions';
import Stats from './Stats';
import {
  Container,
  ActionBar,
  TopContent,
  ProfileContainer,
  StatsContainer,
} from '../styled';
import { BuildConfig } from './helpers';

const RotatingIconWithProps = ({ up, children, ...rest }) => (
  <div {...rest}>{children}</div>
);

const RotatingIcon = styled(RotatingIconWithProps)`
  margin-left: auto;
  transform: ${p => (p.up ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.2s linear;
`;

const Result = ({ 'data-testid': dataTestId, parentRef, ...rest }) => {
  const { isMobile } = useWindowWidth();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeTab, setActiveTab] = useState();
  const lastSuccessfulSearch = useSelector(getLastSuccessfulSearch);
  const config = BuildConfig(
    parentRef,
    rest,
    lastSuccessfulSearch?.highlightQuery
  );

  const toggleCollapse = () => {
    setActiveTab();
    setIsCollapsed(s => !s);
  };

  const handleStatClick = (tab, e) => {
    e && e.stopPropagation();
    if (!config[tab] || tab === activeTab) {
      setActiveTab(null);
      return setIsCollapsed(true);
    }
    if (isCollapsed) setIsCollapsed(false);
    setActiveTab(tab);
  };

  return (
    <Container data-testid={dataTestId}>
      <TopContent onClick={toggleCollapse}>
        <ProfileContainer>
          {!isMobile && <Avatar />}
          <Profile {...rest} />
        </ProfileContainer>
        <StatsContainer>
          <Stats {...rest} onStatClick={handleStatClick} />
        </StatsContainer>
        <RotatingIcon up={!isCollapsed}>
          {/* @ts-ignore */}
          <Icon icon={'chevronDown'} size='sm' color={colours.darkGrey} />
        </RotatingIcon>
      </TopContent>
      <ActionBar>
        <Actions {...rest} />
        {rest.totalMatches > 0 && (
          <Tag>Last matched activity {rest.mostRecentDate}</Tag>
        )}
      </ActionBar>
      <CollapsiblePanel
        isCollapsed={isCollapsed}
        style={{ background: 'white' }}
      >
        <TabbedPanel
          config={config}
          activeTab={activeTab}
          onActiveTabChange={setActiveTab}
        />
      </CollapsiblePanel>
    </Container>
  );
};

export default Result;
