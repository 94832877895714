import React from 'react';
import { views } from '../constants';
import { getResearcherText } from 'utils/constants';

const AlertSuccessContent = ({ message = null, type = views.researchers }) => {
  const defaultMessage = () => {
    return (
      <div>
        All{' '}
        {type === views.researchers
          ? getResearcherText({ isPlural: true })
          : 'organisations'}{' '}
        successfully added.
      </div>
    );
  };

  return (
    <>
      {message === null && defaultMessage()}
      {message !== null && <div>{message}</div>}
    </>
  );
};

export default AlertSuccessContent;
