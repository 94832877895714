import { useState, useEffect } from 'react';
import { Modal, notification } from 'antd';

import { reportResearcher } from 'services/api';
import { usePost } from 'hooks';
import { Input } from 'components/common';

import { getResearcherText } from 'utils/constants';

const Report = ({ researcherId, onToggleModal }) => {
  const [reason, setReason] = useState();
  const [{ res, loading }, postReport] = usePost({ url: reportResearcher });

  useEffect(() => {
    if (res) {
      notification.open({
        message: 'Report Submitted',
        description: 'Thank you for reporting this profile to us for review.',
        placement: 'bottomLeft',
      });
      onToggleModal();
    }
  }, [res, onToggleModal]);

  const handleSubmit = () => {
    postReport({ researcherId, reason });
  };

  return (
    <Modal
      open
      title={`Report ${getResearcherText({ capitalize: true })} Profile`}
      onOk={handleSubmit}
      onCancel={onToggleModal}
      okButtonProps={{ disabled: loading || !reason }}
      cancelButtonProps={{ disabled: loading }}
    >
      Please enter a reason for reporting
      <Input
        placeholder='Reason'
        disabled={loading}
        value={reason}
        onChange={setReason}
        style={{ marginTop: 10 }}
        multiline
      />
    </Modal>
  );
};

export default Report;
