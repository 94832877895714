import { useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getActiveModal,
  getShowMyListsOnly,
  getIncludePausedLists,
  getActiveList,
  getSelectedMemberIds,
  getAreAllMembersSelected,
  getPageNumber,
  getPageSize,
  getTableSort,
  getSelectedMembers,
} from 'selectors/lists';
import { setModal, setActiveList, setListMembers } from 'actions/lists';
import { useGet, usePatch } from 'hooks';
import {
  getListsForUser,
  getMembersForList,
  manageMembers,
} from 'services/api';
import { Create } from 'components/common/Lists/Modals';
import { TITLE_POPUP_CREATE_LIST } from 'components/common/labelConstants';

import Actions from './Actions';
import Members from './Members';
import ListDescription from 'components/common/Lists/ListDescription';
import { Edit, Delete, AddTo, Export, RemoveFrom, PushCRM } from './Modals';
import { NotificationSuccess, NotificationFailure } from './Notifications';

const modals = {
  create: Create,
  createAndAdd: Create,
  edit: Edit,
  delete: Delete,
  addTo: AddTo,
  export: Export,
  removeFrom: RemoveFrom,
  pushCRM: PushCRM,
};

const REDUCER_TYPE_EXTERNAL_SET_ID = 'LIST_REDUCER_TYPE_EXTERNAL_SET_ID';
const REDUCER_TYPE_SET_FILTERS = 'REDUCER_TYPE_SET_FILTERS';
const REDUCER_TYPE_RESPONSE_OK_FETCH_LIST =
  'LIST_REDUCER_TYPE_RESPONSE_OK_FETCH_LIST';
const REDUCER_TYPE_RESPONSE_OK_ADDING_MEMBERS_TO_LIST =
  'LIST_REDUCER_TYPE_RESPONSE_OK_ADDING_MEMBERS_TO_LIST';
const REDUCER_TYPE_RESPONSE_FAIL_ADDING_MEMBERS_TO_LIST =
  'LIST_REDUCER_TYPE_RESPONSE_FAIL_ADDING_MEMBERS_TO_LIST';
const REDUCER_TYPE_CREATE_LIST_AND_ADD_MEMBERS =
  'LIST_REDUCER_TYPE_CREATE_LIST_AND_ADD_MEMBERS';
const REDUCER_TYPE_CREATE_LIST = 'LIST_REDUCER_TYPE_CREATE_LIST';
const REDUCER_TYPE_HANDLE_CLOSE_FORM = 'LIST_REDUCER_TYPE_HANDLE_CLOSE_FORM';
const REDUCER_TYPE_HANDLE_DELETED_LIST = 'REDUCER_TYPE_HANDLE_DELETED_LIST';
const REDUCER_TYPE_REFRESH_LIST = 'LIST_REDUCER_TYPE_REFRESH_LIST';

const dispatchFormStateHandler = (state, action) => {
  switch (action.type) {
    case REDUCER_TYPE_SET_FILTERS:
    case REDUCER_TYPE_EXTERNAL_SET_ID:
    case REDUCER_TYPE_RESPONSE_OK_FETCH_LIST:
    case REDUCER_TYPE_RESPONSE_OK_ADDING_MEMBERS_TO_LIST:
    case REDUCER_TYPE_REFRESH_LIST: {
      return { ...state, ...action.val, Type: action.type };
    }

    case REDUCER_TYPE_RESPONSE_FAIL_ADDING_MEMBERS_TO_LIST: {
      state.Actions.NotificationFailure(action.val.Error.message);
      return { ...state, ...action.val, Type: action.type };
    }

    case REDUCER_TYPE_CREATE_LIST_AND_ADD_MEMBERS: {
      state.Actions.ActivateForm('createAndAdd');
      return { ...state, ...action.val, Type: action.type };
    }

    case REDUCER_TYPE_CREATE_LIST: {
      state.Actions.ActivateForm();
      return { ...state, ...action.val, Type: action.type };
    }

    case REDUCER_TYPE_HANDLE_CLOSE_FORM: {
      if (state.Type === REDUCER_TYPE_CREATE_LIST_AND_ADD_MEMBERS) {
        if (action.val.AllSelected) {
          state.Actions.CloneList(action.val.ListId, action.val.SourceListId);
        } else if (action.val.AllButtonSelected) {
          state.Actions.CloneList(
            action.val.ListId,
            action.val.SourceListId,
            action.val.ExcludedFromAllResearcherIds
          );
        } else {
          state.Actions.CopyResearcherList(
            action.val.ListId,
            action.val.ResearcherIds
          );
        }
      }
      return { ...state, ...action.val, Type: action.type };
    }

    case REDUCER_TYPE_HANDLE_DELETED_LIST: {
      return { ...state, ...action.val, Type: action.type };
    }

    default: {
      return { ...state, Type: action.type ? action.type : state.Type };
    }
  }
};

const Lists = ({
  fetchingListResponse,
  fetchLists,
  loading,
  hasIndustryLicense,
  setDefaultList,
}) => {
  const dispatch = useDispatch();
  const activeListRef = useRef();
  const activeModal = useSelector(getActiveModal);
  const showMyListsOnly = useSelector(getShowMyListsOnly);
  const includePausedLists = useSelector(getIncludePausedLists);
  const activeList = useSelector(getActiveList);
  const { id } = useParams();
  const pageNumber = useSelector(getPageNumber);
  const pageSize = useSelector(getPageSize);
  const sort = useSelector(getTableSort);

  const selectedMemberIds = useSelector(getSelectedMemberIds);
  const selectedMembers = useSelector(getSelectedMembers);
  const allSelected = useSelector(getAreAllMembersSelected);

  const [
    { res: fetchListMembersResponse, loading: fetchListMembersLoading },
    fetchListMembers,
  ] = useGet({
    url: '',
  });

  const [
    {
      res: addingMembersToListResponse,
      loading: loadingAddingMembers,
      error: addingMembersToListError,
    },
    patchMembers,
  ] = usePatch({
    url: '',
  });

  const [formState, dispatchFormState] = useReducer(dispatchFormStateHandler, {
    ListId: -1,
    IncludeDisabled: includePausedLists,
    OnlyShowMine: showMyListsOnly,
    Actions: {
      ActivateForm: (form = 'create') => {
        dispatch(setModal(form));
      },
      CloseForm: () => {
        dispatch(setModal(''));
      },
      FetchList: (includeDisabled, onlyShowMine) => {
        fetchLists({
          url: getListsForUser,
          onMount: true,
          query: {
            IncludeDisabled: includeDisabled,
            OnlyShowMine: onlyShowMine,
          },
        });
      },
      FetchListMembers: listId => {
        if (listId === -1) return;

        fetchListMembers({
          url: getMembersForList(listId),
          query: {
            pageSize,
            pageNumber,
            orderBy: sort.by,
            isAscending: sort.dir === 'asc',
          },
        });
      },
      CloneList: (listId, sourceListId, excludedFromAllResearcherIds) => {
        patchMembers({
          url: manageMembers(listId),
          body: { addFromListId: sourceListId, excludedFromAllResearcherIds },
        });
      },
      CopyResearcherList: (listId, researcherIds) => {
        patchMembers({
          url: manageMembers(listId),
          body: { researcherIds: researcherIds },
        });
      },
      ShowNotificationSuccessfully: () => {
        NotificationSuccess();
      },

      ShowNotificationFailure: message => {
        NotificationFailure(message);
      },
      SetActiveList: list => {
        dispatch(setActiveList(list));
      },
    },
  });

  useEffect(() => {
    dispatchFormState({
      type: REDUCER_TYPE_SET_FILTERS,
      val: {
        IncludeDisabled: includePausedLists,
        OnlyShowMine: showMyListsOnly,
      },
    });
  }, [showMyListsOnly, includePausedLists]);

  useEffect(() => {
    if (fetchListMembersResponse) {
      dispatch(setListMembers(fetchListMembersResponse));
    }
  }, [dispatch, fetchListMembersResponse]);

  useEffect(() => {
    if (id) {
      dispatchFormState({
        type: REDUCER_TYPE_EXTERNAL_SET_ID,
        val: {
          ListId: parseInt(id),
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (fetchingListResponse) {
      dispatchFormState({
        type: REDUCER_TYPE_RESPONSE_OK_FETCH_LIST,
        val: {
          ResearcherList: [...fetchingListResponse],
        },
      });
    }
  }, [fetchingListResponse]);

  // Manage the changes in the creation of new List when use the select box.
  useEffect(() => {
    if (addingMembersToListResponse && addingMembersToListResponse != null) {
      dispatchFormState({
        type: REDUCER_TYPE_RESPONSE_OK_ADDING_MEMBERS_TO_LIST,
      });
    }

    if (addingMembersToListError && addingMembersToListError.status === 400) {
      dispatchFormState({
        type: REDUCER_TYPE_RESPONSE_FAIL_ADDING_MEMBERS_TO_LIST,
        val: {
          Error: addingMembersToListError,
        },
      });
    }
  }, [addingMembersToListResponse, addingMembersToListError]);

  const createNewListHandler = valueType => {
    dispatchFormState({
      type: valueType,
    });
  };

  const closeModalHandler = (
    options = {
      deleteListSuccessfully: false,
      fetchMembers: false,
      fetchLists: false,
      id: null,
    }
  ) => {
    formState.Actions.CloseForm();
    if (options.id && options.id !== null) {
      if (formState.Type === REDUCER_TYPE_CREATE_LIST) {
        dispatchFormState({
          type: REDUCER_TYPE_EXTERNAL_SET_ID,
          val: {
            ListId: parseInt(options.id),
          },
        });
      } else {
        dispatchFormState({
          type: REDUCER_TYPE_HANDLE_CLOSE_FORM,
          val: {
            ListId: options.id,
            SourceListId: activeList.id,
            ResearcherIds: selectedMemberIds,
            AllSelected: allSelected,
            AllButtonSelected: selectedMembers.all,
            ExcludedFromAllResearcherIds: Object.keys(selectedMembers.exclude),
          },
        });
      }
    } else {
      if (options.deleteListSuccessfully) {
        dispatchFormState({
          type: REDUCER_TYPE_HANDLE_DELETED_LIST,
          val: {
            ListId: -1,
          },
        });
      } else {
        if (options.fetchMembers) {
          dispatchFormState({
            type: REDUCER_TYPE_REFRESH_LIST,
            val: {
              ListId: activeList?.id,
            },
          });
        }
        if (options.fetchLists) {
          dispatchFormState({
            type: REDUCER_TYPE_EXTERNAL_SET_ID,
            val: {
              ListId: activeList?.id,
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    activeListRef.current = activeList;
  }, [activeList]);

  useEffect(() => {
    switch (formState.Type) {
      case REDUCER_TYPE_EXTERNAL_SET_ID:
      case REDUCER_TYPE_HANDLE_DELETED_LIST: {
        formState.Actions.FetchList(
          formState.IncludeDisabled,
          formState.OnlyShowMine
        );

        return;
      }
      case REDUCER_TYPE_REFRESH_LIST: {
        formState.Actions.FetchListMembers(formState.ListId);
        return;
      }
      case REDUCER_TYPE_RESPONSE_OK_ADDING_MEMBERS_TO_LIST: {
        formState.Actions.ShowNotificationSuccessfully();
        formState.Actions.FetchList(
          formState.IncludeDisabled,
          formState.OnlyShowMine
        );
        return;
      }

      case REDUCER_TYPE_RESPONSE_OK_FETCH_LIST: {
        let auxListId = formState.ListId;
        if (auxListId === -1 && activeListRef) {
          auxListId = activeListRef.current.id;
        }

        let activeList = {};

        if (setDefaultList)
          activeList = formState.ResearcherList.find(
            f => f.name === setDefaultList
          );
        else
          activeList = formState.ResearcherList.find(f => f.id === auxListId);

        if (activeList) {
          formState.Actions.SetActiveList(activeList);
          formState.Actions.FetchListMembers(activeList.id);
        } else {
          if (formState.ResearcherList.length > 0) {
            formState.Actions.SetActiveList(formState.ResearcherList[0]);
            formState.Actions.FetchListMembers(formState.ResearcherList[0].id);
          } else {
            formState.Actions.SetActiveList({});
            dispatch(setListMembers([]));
          }
        }
        return;
      }
      default: {
        // trick to avoid messages in the compiler.
        return;
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, dispatch]);

  useEffect(() => {
    if (activeModal === 'create') {
      createNewListHandler(REDUCER_TYPE_CREATE_LIST);
    }
  }, [activeModal]);

  const Modal = modals[activeModal];

  const showCreateNewListForm = caa => {
    if (caa) {
      return (
        <Modal
          title={TITLE_POPUP_CREATE_LIST}
          buttonOkText='Create and Add'
          onClose={closeModalHandler}
        />
      );
    } else {
      return <Modal onClose={closeModalHandler} />;
    }
  };

  const onSelectorChangeHandler = listId => {
    const activeList = formState.ResearcherList.find(
      f => f.id === parseInt(listId)
    );
    formState.Actions.SetActiveList(activeList);
    dispatchFormState({
      type: REDUCER_TYPE_EXTERNAL_SET_ID,
      val: {
        ListId: parseInt(listId),
      },
    });
  };

  const onHandlerRefresListAfterPause = () => {
    dispatchFormState({
      type: REDUCER_TYPE_REFRESH_LIST,
      val: {
        ListId: activeList?.id,
      },
    });
  };
  return (
    <>
      <Actions
        researcherLists={formState?.ResearcherList}
        loading={loading || loadingAddingMembers || fetchListMembersLoading}
        onSelectorChange={onSelectorChangeHandler}
        onRefreshListAfterPause={onHandlerRefresListAfterPause}
        hasIndustryLicense={hasIndustryLicense}
      />
      {activeList &&
        activeList.description &&
        !(loading || loadingAddingMembers || fetchListMembersLoading) && (
          <ListDescription description={activeList.description} />
        )}
      <Members
        onCreateNewItemList={() => {
          createNewListHandler(REDUCER_TYPE_CREATE_LIST_AND_ADD_MEMBERS);
        }}
        loading={fetchListMembersLoading}
      />
      {activeModal &&
        showCreateNewListForm(
          formState.type === REDUCER_TYPE_CREATE_LIST_AND_ADD_MEMBERS
        )}
    </>
  );
};

export default Lists;
