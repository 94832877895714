import { useEffect, useMemo } from 'react';
import qs from 'query-string';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAuth, usePost } from 'hooks';
import { FullPageLoader, Route } from 'components/common';
import { log } from 'services/api';
import { capitalise } from 'utils/search';
import { getRestoringState as getRestoringSearchState } from 'selectors/search';
import { getRestoringState as getRestoringPurchasingState } from 'selectors/search';
import { getRestoringState as getRestoringOtherState } from 'selectors/search';
import { getRestoringState as getRestoringListsState } from 'selectors/lists';
import { getRestoringState as getRestoringCalendarState } from 'selectors/calendar';
import { getRestoringState as getRestoringRateLimitsState } from 'selectors/rateLimits';
import { getRestoringState as getRestoringAIState } from 'selectors/ai';

import { isValidCondition } from 'utils/other';

const logActions = {
  researcher: 'ViewedProfileFromSF',
  poster: 'ViewedPosterItemFromSF',
  project: 'ViewedProjectItemFromSF',
  publication: 'ViewedPublicationItemFromSF',
  trial: 'ViewedClinicalTrialItemFromSF',
  item: 'PurchasingItemFromSF',
};

const AuthedRoute = ({ needs, redirect, children, ...rest }) => {
  const { pending, isAuthed, checkUser, user, wasLoggedOut } = useAuth();
  const { pathname, search } = useLocation();
  const { replace } = useHistory();
  const [{ res }, postLog] = usePost({ url: log });
  const restoringSearchState = useSelector(getRestoringSearchState);
  const restoringPurchasingtate = useSelector(getRestoringPurchasingState);
  const restoringOtherState = useSelector(getRestoringOtherState);
  const restoringListsState = useSelector(getRestoringListsState);
  const restoringCalendarState = useSelector(getRestoringCalendarState);
  const restoringRateLimitsState = useSelector(getRestoringRateLimitsState);
  const restoringAIState = useSelector(getRestoringAIState);

  const parsedQueryParams = qs.parse(search);
  const queryParams = useMemo(
    () => ({ redirect: pathname, ...parsedQueryParams }),
    [parsedQueryParams, pathname]
  );
  if (pathname === '/') {
    delete queryParams.redirect;
  }

  const redirectString = qs.stringify(queryParams, { encode: false });

  const [, target, id] = pathname.split('/');

  useEffect(() => {
    if (
      !res &&
      isAuthed &&
      queryParams.from === 'sf' &&
      Object.keys(logActions).includes(target)
    ) {
      postLog({
        description: `${capitalise(target)}Id: ${id} via Salesforce`,
        action: logActions[target],
        outcome: 'Success',
      });
      replace(pathname);
    }
  }, [res, isAuthed, queryParams, target, postLog, id, replace, pathname]);

  useEffect(() => {
    if (!user) {
      checkUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showLoader =
    pending ||
    restoringSearchState ||
    restoringPurchasingtate ||
    restoringOtherState ||
    restoringListsState ||
    restoringCalendarState ||
    restoringRateLimitsState ||
    restoringAIState;

  if (showLoader) return <FullPageLoader />;

  const redirectPath = wasLoggedOut ? '/login' : `/login?${redirectString}`;

  return (
    <Route
      {...rest}
      render={() => {
        if (!isAuthed) return <Redirect to={redirectPath} />;
        if (needs && !isValidCondition(needs, user) && !redirect) return null;
        if (needs && !isValidCondition(needs, user))
          return <Redirect to={redirect} />;
        return children;
      }}
    />
  );
};

export default AuthedRoute;
