import styled from 'styled-components';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { setShowBuilder } from 'actions/search';
import { Form, Button, Select, Icon, SearchButton } from 'components/common';
import { Tooltip } from 'antd';
import { ActionButtons, SearchAreaContainer } from './styled';
import { getShowBuilder } from 'selectors/search';
import SearchBuilder from '../../Search/SearchArea/SearchBuilder';
import OrgFilterField from '../Filters/OrgFilterField';
import {
  clearOrgWorkItemFilters,
  setOrgWorkItemFilterGroups,
  setWorkItemCategoryItemSearchFilterGroup,
} from 'actions/organisation';
import { SearchBar } from '.';
import {
  getOrganisationWorkItemFilter,
  getWorkItemCategoryItemSearchFilter,
  getHasValidItemSearchQuery,
  SLICE,
  getOrganisationWorkItemIsLoading,
} from 'selectors/organisation';
import { useAuth } from 'hooks';
import licenseType from 'models/licenseType';
import { commonDateRanges } from 'services/inputTokens';
import { performBoolSearch, performPhraseSearch } from 'selectors/userSettings';
import BoolErrorMessage from 'components/Search/BoolErrorMessage';
import { SearchDiv } from 'components/Item/styled';

const CategoryCheckboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
`;

const DatePane = styled.div`
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
`;
const CategoryPanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InfoItem = styled.div`
  position: absolute;
  top: -10px;
  right: -5px;
`;

export default function OrganisationWorkItemSearchArea({
  organisation,
  handleSearch,
  handleCancel,
  isReset,
  setReset,
  loading,
}) {
  const dispatch = useDispatch();
  const showBuilder = useSelector(getShowBuilder);
  const handleClear = () => {
    dispatch(clearOrgWorkItemFilters(organisation.id));
    handleSearch();
  };

  const [showBoolError, setShowBoolError] = useState(false);
  const getHasBoolError = useSelector(
    performBoolSearch(SLICE, organisation.id, 'workItemFilters')
  );
  const getHasPhraseError = useSelector(
    performPhraseSearch(SLICE, organisation.id, 'workItemFilters')
  );
  const isDataLoading = useSelector(
    getOrganisationWorkItemIsLoading(organisation.id)
  );
  const isloading = loading || isDataLoading;

  const searchTermRef = useRef();

  const date = useSelector(
    getOrganisationWorkItemFilter(organisation.id, 'date')
  );

  const displayLastMonthTooltip = date === 'one' && isReset;

  const handleSearchBuilderClear = () => {
    dispatch(setOrgWorkItemFilterGroups(organisation.id, 'term', ''));
  };

  const handleDateChange = value => {
    dispatch(setOrgWorkItemFilterGroups(organisation.id, 'date', value));
    handleSearch();
    setReset(false);
  };

  const handleLocalSearch = () => {
    if (showBoolError !== null && (getHasBoolError || getHasPhraseError))
      setShowBoolError(true);
    else handleSearch();
  };

  const handleSearchBuilderSearch = value => {
    dispatch(setOrgWorkItemFilterGroups(organisation.id, 'term', value));
  };

  const handleShowBuilder = () => {
    dispatch(setShowBuilder(true));
  };

  const { hasLicense } = useAuth();
  const hasIndustryLicense = hasLicense([licenseType.industry], true);

  const hasValidQuery = useSelector(
    getHasValidItemSearchQuery(organisation.id)
  );

  return (
    organisation && (
      <SearchAreaContainer>
        <SearchBar>
          <Button
            style={{ marginRight: 10 }}
            type='secondary'
            onClick={handleShowBuilder}
            disabled={isloading}
          >
            <Icon icon='magnifyingGlassPlus' color='#8D8D8D' size='sm' />
            <span style={{ marginLeft: '5px' }}>Open Search Builder</span>
          </Button>
          <SearchDiv ref={searchTermRef}>
            <Form width='100%' onSubmit={handleLocalSearch} style={{ flex: 1 }}>
              <OrgFilterField
                placeholder='(NGS OR "Next generation sequencing") AND CRISPR'
                filterId='term'
                organisationId={organisation.id}
                filterType='input'
                data-testid='org-filter-term'
                size='default'
                setFilterGroup={setOrgWorkItemFilterGroups}
                getFilter={getOrganisationWorkItemFilter}
              />
            </Form>
            {showBoolError ? (
              <BoolErrorMessage
                performSearch={handleSearch}
                hasBoolFailed={getHasBoolError}
                hasParseFailed={getHasPhraseError}
                updateSearchQuery={value => handleSearchBuilderSearch(value)}
                term={getOrganisationWorkItemFilter(organisation.id, 'term')}
                searchTermRef={searchTermRef}
                setShowBoolError={setShowBoolError}
              />
            ) : (
              <></>
            )}
          </SearchDiv>
          <ActionButtons>
            <Button
              type='secondary'
              onClick={handleClear}
              data-testid='clear-search-button'
              disabled={isloading}
            >
              Clear
            </Button>
            <SearchButton
              type='primary'
              data-testid='search-button'
              loading={isloading}
              disabled={!hasValidQuery || isloading}
              onSearch={handleLocalSearch}
              onCancel={handleCancel}
              location='OrganisationWorkItemsSearch'
            />
          </ActionButtons>
        </SearchBar>
        <CategoryPanel>
          <CategoryCheckboxes>
            <span style={{ marginRight: '10px', lineHeight: '30px' }}>
              Search across:
            </span>
            <OrgFilterField
              helpText='Funding'
              icon='award'
              filterType='category'
              filterId='funding'
              organisationId={organisation.id}
              setFilterGroup={setWorkItemCategoryItemSearchFilterGroup}
              getFilter={getWorkItemCategoryItemSearchFilter}
              disabled={isloading}
            >
              Funding
            </OrgFilterField>
            <OrgFilterField
              helpText='Tradeshows'
              icon='image'
              filterType='category'
              filterId='tradeshows'
              organisationId={organisation.id}
              setFilterGroup={setWorkItemCategoryItemSearchFilterGroup}
              getFilter={getWorkItemCategoryItemSearchFilter}
              disabled={isloading}
            >
              Tradeshows
            </OrgFilterField>
            <OrgFilterField
              helpText='Publications'
              icon='book'
              filterType='category'
              filterId='publications'
              organisationId={organisation.id}
              setFilterGroup={setWorkItemCategoryItemSearchFilterGroup}
              getFilter={getWorkItemCategoryItemSearchFilter}
              disabled={isloading}
            >
              Publications
            </OrgFilterField>
            <OrgFilterField
              helpText='Clinical Trials'
              icon='trial'
              filterType='category'
              filterId='clinicalTrials'
              organisationId={organisation.id}
              setFilterGroup={setWorkItemCategoryItemSearchFilterGroup}
              getFilter={getWorkItemCategoryItemSearchFilter}
              disabled={isloading}
            >
              Clinical Trials
            </OrgFilterField>
            {hasIndustryLicense && (
              <>
                <OrgFilterField
                  helpText='3rd Party Data'
                  icon='magnifyingGlass'
                  filterType='category'
                  filterId='profile'
                  organisationId={organisation.id}
                  setFilterGroup={setWorkItemCategoryItemSearchFilterGroup}
                  getFilter={getWorkItemCategoryItemSearchFilter}
                  disabled={isloading}
                >
                  3rd Party Data
                </OrgFilterField>
              </>
            )}
          </CategoryCheckboxes>
          <DatePane>
            <span
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: 'Roboto',
                color: 'rgba(0, 0, 0, 0.85)',
              }}
            >
              Date:{' '}
            </span>
            <Select
              style={{ width: 135 }}
              size='small'
              options={commonDateRanges.filter(x => x.value !== 'custom')}
              onChange={handleDateChange}
              value={date}
              data-testid='filter-date'
              disabled={isloading}
              showSearch
              disableTooltip
            />
            {displayLastMonthTooltip ? (
              <Tooltip
                title={
                  'This filter has been updated because this page has been accessed through the Followed Organisation page.'
                }
                placement='topRight'
              >
                <InfoItem>
                  <Icon icon='circleInfo' color='red' size='sm' />
                </InfoItem>
              </Tooltip>
            ) : (
              <div></div>
            )}
          </DatePane>
        </CategoryPanel>
        {!!showBuilder && (
          <SearchBuilder
            onSearch={handleSearchBuilderSearch}
            showAdvancedFields={false}
            id={organisation.id}
            saveInStore={false}
            onClear={handleSearchBuilderClear}
            filterId='term'
            filterGroup='OrganisationWorkItem'
          />
        )}
      </SearchAreaContainer>
    )
  );
}

OrganisationWorkItemSearchArea.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  handleSearch: PropTypes.func.isRequired,
  isReset: PropTypes.bool,
  setReset: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
