import { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { formatCurrency } from 'utils/number';
import { HelpTooltip } from 'components/common';
import { appendFilter } from 'actions/search';

import { StyledTable, TableContainer } from '../styled';
import { GraphLabel } from './GraphBase/styled';

const Organisations = ({ data }) => {
  const dispatch = useDispatch();

  const handleClick = organisationName => {
    dispatch(appendFilter('organisationName', organisationName));
  };

  if (!data) return null;

  const fundingOrgConfig = {
    headers: [
      {
        label: <div style={{ marginLeft: 'auto' }}>Rank</div>,
        key: 'rank',
        testId: 'rank',
      },
      { label: 'Organisation', key: 'organisationName' },
      {
        label: <div style={{ marginLeft: 'auto' }}>Funding (USD)</div>,
        key: 'fundingAmount',
        testId: 'fundingAmount',
      },
    ],
    createRow: (r, i) => {
      const backgroundColor =
        r.rank % 2 !== 0 ? 'hsl(189, 48%, 55%, 0.04)' : '#ffffff';

      return (
        <Fragment key={`${r.organisation}-${i}`}>
          <div
            style={{
              backgroundColor,
              textAlign: 'right',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => handleClick(r.key)}
          >
            {r.rank}
          </div>
          <div
            style={{ backgroundColor, cursor: 'pointer' }}
            onClick={() => handleClick(r.key)}
          >
            {r.key}
          </div>
          <div
            style={{
              backgroundColor,
              textAlign: 'right',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => handleClick(r.key)}
          >
            {`$${formatCurrency(r.value)}`}
          </div>
        </Fragment>
      );
    },
  };

  return (
    <TableContainer>
      <GraphLabel>
        Funding by Organisation
        <HelpTooltip>
          Organisations ranked by their total funding for your search
        </HelpTooltip>
      </GraphLabel>
      <StyledTable config={fundingOrgConfig} data={data} />
    </TableContainer>
  );
};

export default Organisations;
