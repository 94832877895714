import storage from '../services/storage';

export const types = {
  SET_RESEARCHERS_SEARCH_REQUEST: 'SET_RESEARCHERS_SEARCH_REQUEST',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  INCREASE_PAGE_NUMBER: 'INCREASE_PAGE_NUMBER',
  SET_TOTALS: 'SET_TOTALS',
  LOAD_RESEARCHERS: 'LOAD_RESEARCHERS',
  PERFORM_SEARCH: 'PERFORM_SEARCH',
  RESTORE_STATE: 'ORGANISATION_RESTORE_STATE',
  SET_FILTER_GROUP: 'SET_FILTER_GROUP',
  RESET_FILTERS: 'RESET_FILTERS',
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  SET_SORT: 'SET_SORT',
  SET_EXHIBITOR_FILTER: 'EXHIBITOR_SET_FILTER',
  SET_TRADESHOWS_SEARCH_REQUEST: 'SET_TRADESHOWS_SEARCH_REQUEST',
  PERFORM_TRADESHOWS_SEARCH: 'PERFORM_TRADESHOWS_SEARCH',
  SET_CLINICALTRIALS_SEARCH_REQUEST: 'SET_CLINICALTRIALS_SEARCH_REQUEST',
  PERFORM_CLINICALTRIALS_SEARCH: 'PERFORM_CLINICALTRIALS_SEARCH',
  SET_VIEW: 'SET_VIEW',
  SET_VIEW_RESET_STATE: 'SET_VIEW_RESET_STATE',
  PERFORM_PURCHASING_SEARCH: 'PERFORM_ORGANISATION_PURCHASING_SEARCH',
  CLEAR_PURCHASING_FILTERS: 'CLEAR_ORGANISATION_PURCHASING_FILTERS',
  SET_PURCHASING_SEARCH_REQUEST: 'SET_ORGANISATION_PURCHASING_SEARCH_REQUEST',
  SET_PURCHASING_FILTER: 'SET_ORGANISATION_PURCHASING_FILTER',
  SET_PURCHASING_FILTER_GROUP: 'SET_ORGANISATION_PURCHASING_FILTER_GROUP',
  SET_PURCHASING_SORT: 'SET_ORGANISATION_PURCHASING_SORT',
  SET_PURCHASING_PAGE_SIZE: 'SET_ORGANISATION_PURCHASING_PAGE_SIZE',
  SET_PURCHASING_PAGE_NUMBER: 'SET_ORGANISATION_PURCHASING_PAGE_NUMBER',
  DISMISS_NOTICE: 'SET_DISMISS_NOTICE',
  DISMISS_CLINICAL_TRIAL_NOTICE: 'SET_DISMISS_CLINICAL_TRIAL_NOTICE',
  SET_WORKITEM_SEARCH_REQUEST: 'SET_WORKITEM_SEARCH_REQUEST',
  SET_WORKITEM_PAGE_NUMBER: 'SET_WORKITEM_PAGE_NUMBER',
  SET_WORKITEM_PAGE_SIZE: 'SET_WORKITEM_PAGE_SIZE',
  PERFORM_WORKITEM_SEARCH: 'PERFORM_WORKITEM_SEARCH',
  SET_WORKITEM_SORT: 'SET_WORKITEM_SORT',
  SET_WORKITEM_FILTER_GROUP: 'SET_WORKITEM_FILTER_GROUP',
  SET_WORKITEM_FILTERS_BY_STATE_GROUP: 'SET_WORKITEM_FILTERS_BY_STATE_GROUP',
  RESET_WORKITEM_FILTERS: 'RESET_WORKITEM_FILTERS',
  CLEAR_WORKITEM_FILTERS: 'CLEAR_WORKITEM_FILTERS',
  RESET_ALL_FILTERS: 'RESET_ALL_FILTERS',
  SET_WORKITEM_CATEGORY_FILTER_GROUP: 'SET_WORKITEM_CATEGORY_FILTER_GROUP',
  SET_WORKITEM_CATEGORY_ITEM_SEARCH_FILTER_GROUP:
    'SET_WORKITEM_CATEGORY_ITEM_SEARCH_FILTER_GROUP',
  SET_CLINICALTRIAL_PAGE_NUMBER: 'SET_CLINICALTRIAL_PAGE_NUMBER',
  SET_CLINICALTRIAL_PAGE_SIZE: 'SET_CLINICALTRIAL_PAGE_SIZE',
  SET_CLINICALTRIAL_SORT: 'SET_CLINICALTRIAL_SORT',
  SET_CLINICALTRIAL_FILTER_GROUP: 'SET_CLINICALTRIAL_FILTER_GROUP',
  SET_CLINICALTRIAL_FILTERS_BY_STATE_GROUP:
    'SET_CLINICALTRIAL_FILTERS_BY_STATE_GROUP',
  SET_ORG_RESEARCHER_FILTERS_BY_STATE_GROUP:
    'SET_ORG_RESEARCHER_FILTERS_BY_STATE_GROUP',
  CLEAR_CLINICALTRIAL_FILTERS: 'CLEAR_CLINICALTRIAL_FILTERS',
  CANCEL_SEARCH: 'ORGANISATION_CANCEL_SEARCH',
};

export const setOrgResearchersSearchRequestState = (
  organisationId,
  searchRequest
) => ({
  type: types.SET_RESEARCHERS_SEARCH_REQUEST,
  payload: { organisationId, searchRequest },
});

export const resetFilters = organisationId => ({
  type: types.RESET_FILTERS,
  payload: { organisationId },
});

export const performSearch = (request, organisationId) => ({
  type: types.PERFORM_SEARCH,
  payload: { request, organisationId },
});

export const setFilterGroup = (organisationId, id, value) => ({
  type: types.SET_FILTER_GROUP,
  payload: { organisationId, id, value },
});

export const setWorkItemCategoryFilterGroup = (organisationId, id, value) => ({
  type: types.SET_WORKITEM_CATEGORY_FILTER_GROUP,
  payload: { organisationId, id, value },
});

export const setWorkItemCategoryItemSearchFilterGroup = (
  organisationId,
  id,
  value
) => ({
  type: types.SET_WORKITEM_CATEGORY_ITEM_SEARCH_FILTER_GROUP,
  payload: { organisationId, id, value },
});

export const setSort = (organisationId, value) => ({
  type: types.SET_SORT,
  payload: { organisationId, value },
});

export const setPageNumber = (organisationId, pageNumber) => ({
  type: types.SET_PAGE_NUMBER,
  payload: { organisationId, pageNumber },
});

export const setPageSize = (organisationId, pageSize) => ({
  type: types.SET_PAGE_SIZE,
  payload: { organisationId, pageSize },
});

export const increasePageNumber = organisationId => ({
  type: types.INCREASE_PAGE_NUMBER,
  payload: { organisationId },
});

export const setTotals = (organisationId, totals) => ({
  type: types.SET_TOTALS,
  payload: { organisationId, totals },
});

export const clearFilters = organisationId => ({
  type: types.CLEAR_FILTERS,
  payload: { organisationId },
});

const persistState = async (dispatch, getState) => {
  const state = getState();
  await storage.setItem('organisation', {
    ...state.organisation,
  });
};

export const performTradeshowSearch = (request, organisationId) => ({
  type: types.PERFORM_TRADESHOWS_SEARCH,
  payload: { request, organisationId },
});

export const setTradeshowSearchRequestState = (
  organisationId,
  searchRequest
) => ({
  type: types.SET_TRADESHOWS_SEARCH_REQUEST,
  payload: { organisationId, searchRequest },
});

export const setTradeshowsSearchRequest = (
  organisationId,
  searchState
) => async dispatch => {
  await dispatch(setTradeshowSearchRequestState(organisationId, searchState));
};

export const performClinicalTrialsSearch = (request, body) => ({
  type: types.PERFORM_CLINICALTRIALS_SEARCH,
  payload: { request, body },
});

export const setClinicalTrialsSearchRequestState = (
  organisationId,
  searchRequest
) => ({
  type: types.SET_CLINICALTRIALS_SEARCH_REQUEST,
  payload: { organisationId, searchRequest },
});

export const setClinicalTrialsSearchRequest = (
  organisationId,
  searchState
) => async dispatch => {
  await dispatch(
    setClinicalTrialsSearchRequestState(organisationId, searchState)
  );
};

export const setClinicalTrialPageNumber = (organisationId, pageNumber) => ({
  type: types.SET_CLINICALTRIAL_PAGE_NUMBER,
  payload: { organisationId, pageNumber },
});

export const setClinicalTrialPageSize = (organisationId, pageSize) => ({
  type: types.SET_CLINICALTRIAL_PAGE_SIZE,
  payload: { organisationId, pageSize },
});

export const setClinicalTrialSort = (organisationId, sort) => ({
  type: types.SET_CLINICALTRIAL_SORT,
  payload: { organisationId, sort },
});

export const setOrgClinicalTrialFilterGroups = (organisationId, id, value) => ({
  type: types.SET_CLINICALTRIAL_FILTER_GROUP,
  payload: { organisationId: organisationId, id, value },
});

export const clearOrgClinicalTrialFilters = organisationId => ({
  type: types.CLEAR_CLINICALTRIAL_FILTERS,
  payload: { organisationId },
});

// For in memory exhibitor data filters
export const setExhibitorFilter = (id, value) => (dispatch, getState) => {
  dispatch({
    type: types.SET_EXHIBITOR_FILTER,
    payload: { id, value },
  });
  dispatch(persistState);
};

export const setView = view => ({
  type: types.SET_VIEW,
  payload: { view },
});

export const setViewAndResetState = view => async dispatch => {
  await dispatch({
    type: types.SET_VIEW_RESET_STATE,
    payload: { view },
  });
  await dispatch(persistState);
};

export const setOrgResearchersSearchRequest = (
  organisationId,
  searchState
) => async dispatch => {
  await dispatch(
    setOrgResearchersSearchRequestState(organisationId, searchState)
  );
  await dispatch(persistState);
};

export const restoreOrgResearchersSearchState = state => ({
  type: types.RESTORE_STATE,
  payload: state,
});

export const performPurchasingSearch = (
  request,
  options = { newSearch: false, useLastSuccessfulSearch: false }
) => ({
  type: types.PERFORM_PURCHASING_SEARCH,
  payload: { request, ...options },
});

export const clearPurchasingFilters = {
  type: types.CLEAR_PURCHASING_FILTERS,
};

export const setPurchasingSearchRequest = (
  organisationId,
  searchRequest
) => async dispatch => {
  await dispatch({
    type: types.SET_PURCHASING_SEARCH_REQUEST,
    payload: { organisationId, searchRequest },
  });
  await dispatch(persistState);
};

export const setPurchasingFilter = (id, value) => ({
  type: types.SET_PURCHASING_FILTER,
  payload: { id, value },
});

export const setPurchasingFilterGroup = (group, id, value) => ({
  type: types.SET_PURCHASING_FILTER_GROUP,
  payload: { group, id, value },
});

export const setPurchasingSort = (sort, postSearch) => (dispatch, getState) => {
  dispatch({
    type: types.SET_PURCHASING_SORT,
    payload: { sort },
  });

  dispatch(
    performPurchasingSearch(postSearch, { useLastSuccessfulSearch: true })
  );
};

export const setPurchasingPageSize = (size, postSearch) => (
  dispatch,
  getState
) => {
  dispatch({
    type: types.SET_PURCHASING_PAGE_SIZE,
    payload: { size },
  });

  dispatch(
    performPurchasingSearch(postSearch, { useLastSuccessfulSearch: true })
  );
};

export const setPurchasingPageNumber = (number, postSearch) => (
  dispatch,
  getState
) => {
  dispatch({
    type: types.SET_PURCHASING_PAGE_NUMBER,
    payload: { number },
  });

  dispatch(
    performPurchasingSearch(postSearch, { useLastSuccessfulSearch: true })
  );
};

export const dismissNotice = async dispatch => {
  await dispatch({
    type: types.DISMISS_NOTICE,
  });
  await dispatch(persistState);
};

export const dismissClinicalTrialNotice = async dispatch => {
  await dispatch({
    type: types.DISMISS_CLINICAL_TRIAL_NOTICE,
  });
  await dispatch(persistState);
};

export const setWorkItemSearchRequest = (organisationId, searchRequest) => ({
  type: types.SET_WORKITEM_SEARCH_REQUEST,
  payload: { organisationId, searchRequest },
});

export const setWorkItemPageNumber = (organisationId, pageNumber) => ({
  type: types.SET_WORKITEM_PAGE_NUMBER,
  payload: { organisationId, pageNumber },
});

export const setWorkItemPageSize = (organisationId, pageSize) => ({
  type: types.SET_WORKITEM_PAGE_SIZE,
  payload: { organisationId, pageSize },
});

export const setWorkItemSort = (organisationId, sort) => ({
  type: types.SET_WORKITEM_SORT,
  payload: { organisationId, sort },
});

export const performWorkItemSearch = (request, organisationId) => ({
  type: types.PERFORM_WORKITEM_SEARCH,
  payload: { organisationId, request },
});

export const setOrgWorkItemsSearchRequest = (
  organisationId,
  searchState
) => async dispatch => {
  await dispatch(setWorkItemSearchRequest(organisationId, searchState));
  await dispatch(persistState);
};

export const setOrgWorkItemFilterGroups = (organisationId, id, value) => ({
  type: types.SET_WORKITEM_FILTER_GROUP,
  payload: { organisationId: organisationId, id, value },
});

export const setOrgWorkItemFiltersByStateGroups = (
  organisationId,
  newState
) => ({
  type: types.SET_WORKITEM_FILTERS_BY_STATE_GROUP,
  payload: { organisationId: organisationId, newState },
});

export const setOrgClinicalTrialsiltersByStateGroups = (
  organisationId,
  newState
) => ({
  type: types.SET_CLINICALTRIAL_FILTERS_BY_STATE_GROUP,
  payload: { organisationId: organisationId, newState },
});

export const setOrgResearcheriltersByStateGroups = (
  organisationId,
  newState
) => ({
  type: types.SET_ORG_RESEARCHER_FILTERS_BY_STATE_GROUP,
  payload: { organisationId: organisationId, newState },
});

export const clearOrgWorkItemFilters = organisationId => ({
  type: types.CLEAR_WORKITEM_FILTERS,
  payload: { organisationId },
});

export const resetOrgWorkItemFilters = organisationId => ({
  type: types.RESET_WORKITEM_FILTERS,
  payload: { organisationId },
});

export const resetAllFilters = (organisationId, hasIndustryLicense) => ({
  type: types.RESET_ALL_FILTERS,
  payload: { organisationId, hasIndustryLicense },
});

export const cancelSearch = organisationId => ({
  type: types.CANCEL_SEARCH,
  payload: { organisationId },
});
