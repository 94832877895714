import { notification } from 'antd';

export const EwrcaTypes = Object.freeze({
  Ewrca: 'ewrca',
  OrganisationExportEwrca: 'organisationOnlyExportEwrca',
});

export const exportNotification = largeExport => ({
  message: 'Generating Report',
  description: `We are generating your report and will notify you ${
    largeExport ? 'via email' : ''
  } when it is ready.`,
  placement: 'bottomLeft',
});

export const ewrcaNotification = {
  message: 'Cannot Process',
  description:
    'This export is too large to process, please refine your search and try again.',
  placement: 'bottomLeft',
};

export const exportAgainNotification = {
  message: 'Cannot Process',
  description: 'Please try export again.',
  placement: 'bottomLeft',
};

export const failedNotification = {
  message: 'Cannot Process',
  description: 'Failed to export.',
  placement: 'bottomLeft',
};

export const handleExportNotification = onClose => {
  const toOpen = exportNotification();
  notification.open({ ...toOpen, onClose });
};

export const handleFailedExportNotification = errorStatus => {
  const toOpen =
    errorStatus === 412 ? exportAgainNotification : failedNotification;
  notification.open({ ...toOpen });
};
