import * as FromExportHistory from 'reducers/exportHistory/selectors';

export const SLICE = 'exportHistory';

export const getExportToRefresh = state =>
  FromExportHistory.getExportToRefresh(state[SLICE]);
export const getFilters = userId => state =>
  FromExportHistory.getFilters(userId, state[SLICE]);
export const getDateOption = userId => state =>
  FromExportHistory.getDateOption(userId, state[SLICE]);
