import { Fragment, useEffect, useRef, useState } from 'react';
import ActionButtons from '../ActionButtons';
import { EXPORT_TYPE, StatusButtonConfig } from '../ExportHistoryHelper';
import { ExportHistoryTable, StatusButton } from '../styled';

import dayjs from 'dayjs';
import SearchTermsDisplay from 'components/common/SearchTermDisplay';
import FiltersApplied from 'components/common/FiltersApplied';
import { downloadType } from 'components/Export/Summary/constants';
import { searchOrigin } from 'components/Search/Results/constants';
import {
  nonEditableExportHistorySearchExcludedFilters,
  tokenise,
} from 'utils/filters';
import { GetSearchKeysByView } from 'components/Search/Filters/keys';
import {
  AcademicTableHeader,
  BioPharmaTableHeader,
} from 'components/common/Table/styled';
import { isAcademicFull, isIndustryFull } from 'utils/helpers/license';
import ViewCRMSummary from '../Modal/ViewCRMSummary';
import { Tooltip } from 'antd';

export const tablePerPage = [
  { value: 10, label: '10 per page' },
  { value: 25, label: '25 per page' },
  { value: 50, label: '50 per page' },
  { value: 100, label: '100 per page' },
];

const AllExportsTable = ({ user, data, isLoading, updateFilters, filters }) => {
  const ref = useRef();
  const [showCRMSummaryView, setShowCRMSummaryView] = useState(false);
  const [selectedCRM, setSelectedCRM] = useState();
  const researchFields = GetSearchKeysByView(searchOrigin.researcherSearch);
  const orgFields = GetSearchKeysByView(searchOrigin.organisationSearch);
  const purchasingFields = GetSearchKeysByView(searchOrigin.purchasingSearch);
  const orgPageFields = GetSearchKeysByView(
    searchOrigin.organisationPageSearch
  );
  const tradeshowFields = GetSearchKeysByView(searchOrigin.tradeshow);

  useEffect(() => {
    let timeoutId = null;
    if (!showCRMSummaryView) {
      timeoutId = setTimeout(() => setSelectedCRM(null), 200);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showCRMSummaryView]);

  const GetSearchOriginFromExportType = type => {
    if (
      type === downloadType.organisationSearch ||
      type === downloadType.organisationOnlyExport
    )
      return searchOrigin.organisationSearch;
    if (type === downloadType.purchasingSearch)
      return searchOrigin.purchasingSearch;
    if (type === downloadType.tradeshowSearch) return searchOrigin.tradeshow;
    if (type === downloadType.researcherList)
      return searchOrigin.researcherList;
    if (type === downloadType.researcherList)
      return searchOrigin.organisationList;
    if (
      type === downloadType.organisationClinicalTrialsSearch ||
      type === downloadType.organisationResearchersSearch ||
      type === downloadType.organisationActivitySearch
    )
      return searchOrigin.organisationPageSearch;
    else return searchOrigin.researcherSearch;
  };

  const headers = [
    {
      label: 'Filename',
      key: 'filename',
      minWidth: 120,
      maxContentWidth: 180,
    },
    {
      label: 'Term',
      minWidth: 50,
    },
    {
      label: 'Type',
      key: 'type',
      minWidth: 100,
    },
    {
      label: 'Filters',
      minWidth: 120,
    },
    {
      label: 'Emails',
      key: 'numberOfEmails',
      minWidth: 50,
    },
    {
      label: 'Date',
      key: 'dateCreated',
      minWidth: 80,
    },
    {
      label: 'Status',
      key: 'status',
      minWidth: 100,
    },
    {
      minWidth: 160,
    },
  ];

  let offset = 0;
  if (user.isUserManager) {
    headers.splice(1, 0, {
      label: 'User',
      key: 'userName',
      minWidth: 100,
    });
    offset++;
  }

  const showAcademicCredits =
    user.showAcademicCredits &&
    user.isOnCreditSystem &&
    (user.isUserManager || isAcademicFull(user));

  if (showAcademicCredits) {
    headers.splice(4 + offset, 0, {
      label: <AcademicTableHeader />,
      key: 'academicCredits',
      minWidth: 60,
      title: 'Academic Credits Used',
    });
    offset++;
  }

  const showIndustryCredits =
    user.showIndustryCredits &&
    user.isOnCreditSystem &&
    (user.isUserManager || isIndustryFull(user));

  if (showIndustryCredits) {
    headers.splice(4 + offset, 0, {
      label: <BioPharmaTableHeader />,
      key: 'biopharmaCredits',
      minWidth: 60,
    });
    offset++;
  }

  function onCloseViewCRM() {
    setShowCRMSummaryView(false);
  }

  function selectCRM(crm) {
    if (crm) {
      setSelectedCRM(crm);
      setShowCRMSummaryView(true);
    }
  }

  let config = {
    headers: headers,
    createRow: (x, i) => {
      let parsedSearch = x.structuredSearch
        ? JSON.parse(x.structuredSearch)
        : null;

      if (parsedSearch) {
        parsedSearch = Object.fromEntries(
          Object.entries(parsedSearch).filter(([_, value]) => {
            if (value != null && value !== '') {
              return true;
            }
            return false;
          })
        );
      }
      let searchKeys;
      if (x.type === downloadType.researcherSearch) {
        searchKeys = researchFields;
      } else if (x.type === downloadType.purchasingSearch) {
        searchKeys = purchasingFields;
      } else if (x.type === downloadType.tradeshowSearch) {
        searchKeys = tradeshowFields;
      } else if (
        x.type === downloadType.organisationActivitySearch ||
        x.type === downloadType.organisationClinicalTrialsSearch ||
        x.type === downloadType.organisationResearchersSearch
      ) {
        searchKeys = orgPageFields;
      } else {
        searchKeys = orgFields;
      }
      const tokens = parsedSearch
        ? tokenise(parsedSearch, searchKeys, [
            ...nonEditableExportHistorySearchExcludedFilters,
          ])
        : [];

      const statusButton = StatusButtonConfig[x.status] || {
        text: 'Unknown',
        color: '#6c757d',
      };

      return (
        <Fragment key={`${x.id}`}>
          <div
            title={x.name}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {x.name}
          </div>
          {user.isUserManager && <>{x.userName}</>}
          <SearchTermsDisplay
            previousEmailsExcluded={x.previousEmailsExcluded}
            searchTerm={`${x.term || ''} ${
              parsedSearch && parsedSearch['term']
                ? ((x.term || '') && ' AND ') + parsedSearch['term']
                : ''
            }`}
            parentRef={ref}
          />
          <>{EXPORT_TYPE[x.type]?.label}</>
          <FiltersApplied
            parsedSearch={parsedSearch}
            tokens={tokens}
            parentRef={ref}
            searchOrigin={GetSearchOriginFromExportType(x.type)}
            excludeTokens={nonEditableExportHistorySearchExcludedFilters}
            noTokensDisplay={<div>{'N/A'}</div>}
          />
          {showAcademicCredits && (
            <div>{x.academicCredits != null ? x.academicCredits : 'N/A'}</div>
          )}
          {showIndustryCredits && (
            <div>{x.biopharmaCredits != null ? x.biopharmaCredits : 'N/A'}</div>
          )}
          <div>{x.numberOfEmails != null ? x.numberOfEmails : 'N/A'}</div>
          <div>{dayjs(x.dateCreated).format('Do MMM YYYY')}</div>
          <Tooltip title={statusButton.tooltip} placement='right'>
            <StatusButton
              color={statusButton.color}
              background={statusButton.background}
            >
              {statusButton.jsx || statusButton.text}
            </StatusButton>
          </Tooltip>
          <div>
            <ActionButtons
              organisationId={x.superOrganisationId}
              type={x.type}
              status={x.status}
              structuredSearch={parsedSearch}
              downloadGuid={x.downloadGuid}
              researcherListId={x.researcherListId}
              researcherId={x.superResearcherId}
              setSelectedCRM={selectCRM}
              selectedCRM={x.type === downloadType.crmPush ? x : null}
              organisationListId={x.organisationListId}
              canDownload={!user.disableExport}
            />
          </div>
        </Fragment>
      );
    },
  };

  function setPageSize(pageSize) {
    updateFilters({ ...filters, pageSize });
  }

  function setPageNumber(pageNumber) {
    updateFilters({ ...filters, pageNumber });
  }

  function handleSortChange(orderBy) {
    let order;
    if (orderBy !== filters.orderBy) {
      order = 'asc';
    } else {
      order = filters.order === 'desc' ? 'asc' : 'desc';
    }

    if (
      filters.orderBy !== 'dateCreated' &&
      filters.orderBy === orderBy &&
      filters.order === 'desc'
    ) {
      orderBy = 'dateCreated';
      order = 'desc';
    }

    updateFilters({ ...filters, orderBy, order });
  }

  return (
    <div ref={ref}>
      <ExportHistoryTable
        config={config}
        data={data?.data ?? []}
        loading={isLoading}
        pageSizeOptions={tablePerPage}
        pageSize={filters.pageSize ?? 10}
        pageNumber={filters.pageNumber ?? 0}
        resultSize={data?.total ?? 0}
        onSortChange={handleSortChange}
        sort={filters.orderBy}
        sortDir={filters.order}
        onChangePageSize={setPageSize}
        onChangePageNumber={setPageNumber}
        paginate
        sortable
      ></ExportHistoryTable>
      <ViewCRMSummary
        open={showCRMSummaryView}
        onClose={onCloseViewCRM}
        selectedCRM={selectedCRM}
      />
    </div>
  );
};

export default AllExportsTable;
