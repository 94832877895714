import SocialMediaLink from './SocialMediaLink';
import { SocialMediaLinksContainer } from './styled';
import { makeHttpsUrl } from 'utils/helpers/externalLink';

const SocialMediaLinks = ({ researcher }) => {
  const { researcherProfile } = researcher || {};

  const thirdPartyProfiles = researcherProfile.thirdPartyProfiles || [];
  const transformedProfileData = thirdPartyProfiles.reduce((acc, network) => {
    return {
      ...acc,
      [network.type.toLowerCase()]: network.thirdPartyId,
    };
  }, {});

  const makeCustomSearchUrl = siteToFilterBy =>
    `http://www.google.com/search?q=${researcherProfile.researcherFullName} ${
      researcherProfile.organisationName
    }${siteToFilterBy ? ' site:' + siteToFilterBy : ''}`;

  const networks = [
    {
      type: 'linkedin',
      displayName: 'LinkedIn',
      customSearchUrl: makeCustomSearchUrl('linkedin.com'),
    },
    {
      type: 'google',
      displayName: 'Google',
      customSearchUrl: makeCustomSearchUrl(null),
    },
    {
      type: 'researchgate',
      displayName: 'Research Gate',
      customSearchUrl: makeCustomSearchUrl('researchgate.net'),
    },
    {
      type: 'twitter',
      displayName: 'X',
      customSearchUrl: makeCustomSearchUrl('x.com'),
    },
    {
      type: 'academia',
      displayName: 'Academia',
      customSearchUrl: makeCustomSearchUrl('academia.edu'),
    },
  ];

  return (
    <SocialMediaLinksContainer>
      {networks.map(n => {
        const url = transformedProfileData[n.type] || n.customSearchUrl;
        const title =
          (transformedProfileData[n.type] ? 'View on ' : 'Search for ') +
          n.displayName;
        return (
          <SocialMediaLink
            network={n}
            key={n.type}
            url={makeHttpsUrl(url)}
            title={title}
            isMuted={!transformedProfileData[n.type]}
            logDescription={{
              entityType: 'Researcher',
              location: 'ResearcherProfile',
            }}
          />
        );
      })}
    </SocialMediaLinksContainer>
  );
};

export default SocialMediaLinks;
