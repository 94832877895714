import { useCallback, useEffect } from 'react';

import useXHR from './useXHR';

const usePost = ({ url, body, onMount }) => {
  const [state, req, setRes, setErr, setOriginalRequest, cancel] = useXHR(
    onMount
  );
  const requestText = JSON.stringify(body);

  const send = useCallback(
    bodyOrConfig => {
      let latestUrl = url;
      let latestBody = requestText;

      if (bodyOrConfig && !bodyOrConfig.url && !bodyOrConfig.body) {
        latestBody = JSON.stringify(bodyOrConfig);
      }

      if (bodyOrConfig?.url) {
        latestUrl = bodyOrConfig.url;
      }

      if (bodyOrConfig?.body) {
        latestBody = JSON.stringify(bodyOrConfig.body);
      }

      setOriginalRequest({
        method: 'POST',
        url: latestUrl,
        headers: [{ key: 'Content-Type', value: 'application/json' }],
        body: latestBody,
      });
      req.open('POST', latestUrl);
      req.setRequestHeader('Content-Type', 'application/json');
      const token = localStorage.getItem('token');
      if (token) {
        req.setRequestHeader('Authorization', `Bearer ${token}`);
      }
      req.send(latestBody);
      return cancel;
    },
    [req, url, requestText, setOriginalRequest, cancel]
  );

  useEffect(() => {
    if (onMount) {
      send();
    }
    return () => req.abort();
  }, [onMount, send, req]);

  return [state, send, setRes, setErr];
};

export default usePost;
