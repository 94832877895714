const CONTACT = 'contact';

export function getResearcherText({
  isPlural = false,
  capitalize = false,
} = {}) {
  let text = isPlural ? `${CONTACT}s` : CONTACT;

  if (capitalize) {
    text = text.charAt(0).toUpperCase() + text.slice(1);
  }

  return text;
}
