import Export from 'components/Export';
import { downloadType } from 'components/Export/Summary/constants';
import { useAuth } from 'hooks';
import licenseType from 'models/licenseType';

const PurchasingExport = ({ onExport, canExport }) => {
  const { user, hasLicense } = useAuth();

  if (user.disableExport) {
    return null;
  }

  const hasLicensetoExport = hasLicense([licenseType.academic], false);
  if (!hasLicensetoExport) return null;

  return (
    <Export
      disabled={!canExport}
      downloadType={downloadType.purchasingSearch}
      onExport={onExport}
      style={{ marginTop: '2px' }}
      canUseCredits={false}
    >
      Export Data
    </Export>
  );
};

export default PurchasingExport;
