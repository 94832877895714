import PurchasingSearchArea from './SearchArea';
import PurchasingResults from './Results';
import PurchasingNotice from './PurchasingNotice';

const OrganisationPurchasing = ({ organisation, loading, handleCancel }) => {
  return (
    <>
      <PurchasingSearchArea
        organisation={organisation}
        handleCancel={handleCancel}
      />
      <PurchasingNotice />
      <PurchasingResults organisation={organisation} loading={loading} />
    </>
  );
};

export default OrganisationPurchasing;
