import { Pane as CommonPane } from 'components/common';
import { getResearcherText } from 'utils/constants';

import styled from 'styled-components';
const Pane = styled(CommonPane)`
  .content {
    font-size: 13px;
  }
`;

const NotFound = () => {
  return (
    <Pane title='Whoops!' style={{ fontSize: 13 }}>
      <p>
        We cannot find the requested {getResearcherText()}, or your current
        license may not permit access to this profile within the database.
      </p>
      <p>
        If you believe you should have access, please reach out to Live Chat
      </p>
    </Pane>
  );
};

export default NotFound;
