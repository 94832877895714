import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Pane, Form, Button, SearchButton, Icon } from 'components/common';
import {
  performSearch,
  clearFilters,
  setFilter,
  cancelSearch,
} from 'actions/purchasing';
import { getFilter, SLICE, getSearchRequest } from 'selectors/purchasing';
import { performBoolSearch, performPhraseSearch } from 'selectors/userSettings';
import BoolErrorMessage from 'components/Search/BoolErrorMessage';
import { SearchDiv } from 'components/Item/styled';
import { setShowBuilder } from 'actions/search';
import { getShowBuilder } from 'selectors/search';
import SearchBuilder from 'components/Search/SearchArea/SearchBuilder';

import Filter from './helpers/FilterField';
import { Actions, Buttons, SearchBar } from './styled';
import { PurchasingContext } from './';

const SearchArea = () => {
  const postSearch = useContext(PurchasingContext);
  const dispatch = useDispatch();
  const showBuilder = useSelector(getShowBuilder);

  const searchTermRef = useRef();
  const [showBoolError, setShowBoolError] = useState(false);
  const getHasBoolError = useSelector(performBoolSearch(SLICE));
  const getHasPhraseError = useSelector(performPhraseSearch(SLICE));
  const { loading } = useSelector(getSearchRequest);

  useEffect(() => {
    return () => {
      dispatch(cancelSearch(true));
    };
  }, [dispatch]);

  const handleSearch = () => {
    if (showBoolError !== null && (getHasBoolError || getHasPhraseError)) {
      setShowBoolError(true);
    } else {
      handlePerformSearch();
    }
  };

  const handleSearchBuilderSearch = value => {
    updateSearch(value);
    handlePerformSearch();
  };

  const handleSearchBuilderClear = () => {
    updateSearch('');
  };

  const handlePerformSearch = () => {
    dispatch(performSearch(postSearch, { newSearch: true }));
  };

  const updateSearch = value => {
    dispatch(setFilter('term', value));
  };

  const handleClear = () => {
    dispatch(clearFilters);
  };

  const handleCancel = () => {
    dispatch(cancelSearch());
  };

  const handleShowBuilder = () => {
    dispatch(setShowBuilder(true));
  };

  return (
    <Pane title='Purchasing Search'>
      <SearchBar>
        <Button
          style={{ marginRight: 10 }}
          type='secondary'
          size='large'
          onClick={handleShowBuilder}
          disabled={loading}
        >
          <Icon icon='magnifyingGlassPlus' color='#8D8D8D' size='sm' />
          <span style={{ marginLeft: '5px' }}>Open Search Builder</span>
        </Button>
        <SearchDiv ref={searchTermRef}>
          <Form style={{ flex: 1 }} width='100%' onSubmit={handleSearch}>
            <Filter
              filterType='input'
              placeholder='Enter a search term'
              filterId='term'
              size='large'
              disabled={loading}
            />
          </Form>
          {showBoolError ? (
            <BoolErrorMessage
              updateSearchQuery={value => updateSearch(value)}
              performSearch={handlePerformSearch}
              hasBoolFailed={getHasBoolError}
              hasParseFailed={getHasPhraseError}
              term={getFilter('term')}
              searchTermRef={searchTermRef}
              setShowBoolError={setShowBoolError}
            />
          ) : (
            <></>
          )}
        </SearchDiv>
        <Actions>
          <Buttons>
            <Button
              type='secondary'
              data-testid='clear-search-button'
              onClick={handleClear}
              size={'large'}
              disabled={loading}
            >
              Clear
            </Button>
            <SearchButton
              type='primary'
              data-testid='search-button'
              size={'large'}
              loading={loading}
              disabled={loading}
              onSearch={handleSearch}
              onCancel={handleCancel}
              location='PurchasingSearch'
            />
          </Buttons>
        </Actions>
      </SearchBar>
      {!!showBuilder && (
        <SearchBuilder
          onSearch={handleSearchBuilderSearch}
          showAdvancedFields={false}
          id='purchasingSearch'
          onClear={handleSearchBuilderClear}
          filterId='term'
          filterGroup='purchasingSearch'
          tokenValue='Lab Pipette'
        />
      )}
    </Pane>
  );
};

export default SearchArea;
