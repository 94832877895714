import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { countries, states, timeAtCompany } from 'services/inputTokens';
import styled from 'styled-components';
import OrgFilterField from './OrgFilterField';
import {
  resetOrgWorkItemFilters,
  setOrgWorkItemFilterGroups,
} from 'actions/organisation';
import { Button, SearchButton } from 'components/common';
import { getOrganisationWorkItemFilter } from 'selectors/organisation';
import { tagRender } from 'components/common/TagRender';
import { renderOption } from 'components/common/OptionRender';

const Content = styled.div`
  padding: 0px 0px 10px;
  > * {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 5px;
    }
  }
`;

export const Actions = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const OrganisationWorkItemFilters = ({
  organisationId,
  onFilterUpdate,
  loading,
  handleCancel,
}) => {
  const dispatch = useDispatch();

  const handleSearch = () => {
    onFilterUpdate();
  };

  const handleReset = () => {
    dispatch(resetOrgWorkItemFilters(organisationId));
  };
  return (
    <>
      <Content>
        <OrgFilterField
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          renderOption={renderOption}
          tagRender={tagRender}
          filterId='country'
          organisationId={organisationId}
          placeholder='Region/Country'
          options={countries}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
          disabled={loading}
          remoteSearchOnClick
        />
        <OrgFilterField
          filterType='token'
          placeholder='Cities'
          filterId='city'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
          disabled={loading}
        />
        <OrgFilterField
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          tagRender={tagRender}
          placeholder='States'
          options={states}
          filterId='state'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
          optionProp={{
            optionLabel: 'displayName',
            optionValue: 'id',
          }}
          disabled={loading}
          remoteSearchOnClick
        />
        <OrgFilterField
          filterType='token'
          filterId='jobTitle'
          placeholder='Job Title'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
          disabled={loading}
        />
        <OrgFilterField
          filterType='token'
          filterId='lastName'
          placeholder='Last Name'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
          disabled={loading}
        />
        <OrgFilterField
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          renderOption={renderOption}
          tagRender={tagRender}
          options={timeAtCompany}
          filterId='timeAtCompany'
          placeholder='Time at Company'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
          disabled={loading}
        />
        <OrgFilterField
          filterType='checkbox'
          checkValue={organisationId}
          filterId='sponsorSuperOrgId'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
          disabled={loading}
          labelText='Clinical Trial Sponsor'
        />
      </Content>
      <Actions>
        <Button
          data-testid='filter-reset'
          onClick={handleReset}
          disabled={loading}
        >
          Reset
        </Button>
        <SearchButton
          type='primary'
          style={{ marginLeft: '10px' }}
          data-testid='filter-update'
          loading={loading}
          disabled={loading}
          onSearch={handleSearch}
          onCancel={handleCancel}
          location='OrganisationWorkItemsSearch'
          searchText='Update'
        />
      </Actions>
    </>
  );
};

OrganisationWorkItemFilters.propTypes = {
  organisationId: PropTypes.number.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
