import { Skeleton } from 'antd';
import { UpgradeLicense } from 'components/common';
import { Container, Pane, Row } from './styled';
import { getResearcherText } from 'utils/constants';

function CTA() {
  return (
    <>
      <Container style={{ filter: 'blur(2px)' }}>
        <Row>
          <Pane title='Project Details'>
            <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
          </Pane>
        </Row>
        <Row>
          <Pane title='Funding Details'>
            <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
          </Pane>
          <Pane title={`${getResearcherText({ capitalize: true })} Details`}>
            <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
          </Pane>
          <Pane title='Organisation Details'>
            <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
          </Pane>
        </Row>
      </Container>
      <UpgradeLicense />
    </>
  );
}

export default CTA;
