import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal as AntModal, Alert, notification } from 'antd';

import { usePost } from 'hooks';
import { createUser, editUser, toggleUser } from 'services/api';
import { Checkbox, Input, Switch } from 'components/common';
import Footer from './ModalFooter';
import { crmTypes } from 'utils/helpers/crm';
import EditLicense from './EditLicense';
import EditFeature from './EditFeature';
import { allocationChanged } from '../constants';
import { getResearcherText } from 'utils/constants';

const Content = styled.div`
  > * {
    margin-bottom: 10px;
  }
  .switch {
    margin-right: 10px;
  }
`;

const SwitchGroup = styled.div`
  display: flex;
  justify-content: ${props => (props.isEdit ? 'flex-start' : 'space-between')};
`;

const Title = styled.div`
  font-weight: 500;
`;

const defaultError = isEdit =>
  `Something went wrong while ${
    isEdit ? 'editing' : 'creating'
  } this user, please try again later or contact support.`;

const AddUser = ({
  setModal,
  fetchUsers,
  licenseAllocation,
  enterpriseAccount,
  activeUser: user,
}) => {
  const [fullName, setFullName] = useState(user ? user.fullname : '');
  const [email, setEmail] = useState(user ? user.email : '');
  const [requiresTraining, setRequiresTraining] = useState(true);
  const [hideEmails, setHideEmails] = useState(user ? user.hideEmails : false);
  const [disableExports, setDisableExports] = useState(
    user ? user.disableExports : false
  );
  const [userManagementRole, setUserManagementRole] = useState(
    user ? user.isUserManagementAdmin : false
  );

  const [licenses, setLicenses] = useState(
    user
      ? user.licenses
          .filter(x => !x.demoMode)
          .map(x => ({
            type: x.type,
          }))
      : []
  );
  const [integrations, setIntegrations] = useState(user?.integrations ?? []);

  const [{ res, error, loading: editLoading }, postCreateUser] = usePost({
    url: user ? editUser : createUser,
  });
  const [
    { res: disabledRes, error: disableError, loading: disabledLoading },
    postToggleUser,
  ] = usePost({ url: toggleUser });

  const loading = editLoading || disabledLoading;

  useEffect(() => {
    if (res) {
      setModal();
      if (user) {
        fetchUsers();
      }
    }
  }, [res, setModal, fetchUsers, user]);

  useEffect(() => {
    // license limits error
    if (error?.status === 412) {
      fetchUsers();
      setModal();
      notification.warning({
        message: `Failed to ${user ? 'edit' : 'create'} user`,
        description: <div>{allocationChanged}</div>,
        placement: 'top',
      });
    }
  }, [error, setModal, user, fetchUsers]);

  useEffect(() => {
    // license limits error
    if (disableError) {
      fetchUsers();
      setModal();
      notification.warning({
        message: `Failed to disable user`,
        description: (
          <div>
            {disableError?.status === 500 ? disableError?.statusText : ''}
          </div>
        ),
        placement: 'top',
      });
    }
  }, [disableError, setModal, user, fetchUsers]);

  const handleOk = () => {
    postCreateUser({
      userId: user?.userId,
      fullName,
      email,
      requiresTraining,
      isUserManager: userManagementRole,
      licenses,
      canPushToSalesforce: integrations.some(t => t === crmTypes.SALESFORCE),
      canPushToHubspot: integrations.some(t => t === crmTypes.HUBSPOT),
      disableExports: disableExports,
      hideEmails: hideEmails,
    });
  };

  useEffect(() => {
    if (disabledRes) {
      setModal();
      fetchUsers();
    }
  }, [disabledRes, fetchUsers, setModal]);

  const handleDisable = () => {
    postToggleUser({
      newUserId: user.userId,
      enabled: false,
    });
  };

  const toggleIntegrations = type => {
    setIntegrations(x => {
      const res = [...x];
      if (res.some(t => t === type)) {
        return res.filter(t => t !== type);
      }

      res.push(type);
      return res;
    });
  };

  const toggleLicenses = type => {
    setLicenses(x => {
      const res = [...x];
      if (res.some(t => t.type === type)) {
        return res.filter(t => t.type !== type);
      }

      res.push({
        type,
      });
      return res;
    });
  };

  return (
    <AntModal
      open
      title={user ? 'Edit User' : 'Add User'}
      onCancel={() => setModal(null)}
      footer={
        <Footer
          isEdit={!!user}
          isLoading={loading}
          submitDisabled={
            !fullName ||
            !email ||
            !email.match(
              //eslint-disable-next-line no-control-regex
              /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
            ) ||
            (!licenses.length && !user)
          }
          disableDisabled={user?.licenses?.some(l => l.isExpired)}
          onSubmit={handleOk}
          submitBtnText={user ? 'Update' : 'Add'}
          onCancel={() => setModal()}
          onDisable={handleDisable}
        />
      }
    >
      <Content>
        <Input
          disabled={loading || user}
          value={fullName}
          onChange={setFullName}
          placeholder='Full Name'
        />
        <Input
          disabled={loading || user}
          value={email}
          onChange={setEmail}
          placeholder='Email'
        />
        <SwitchGroup isEdit={!!user}>
          {!user && (
            <Switch
              disabled={loading}
              size='small'
              onChange={setRequiresTraining}
              checked={requiresTraining}
              label='Request Training'
            />
          )}
          <Switch
            disabled={loading}
            size='small'
            onChange={setUserManagementRole}
            checked={userManagementRole}
            label='User Management Role'
          />
        </SwitchGroup>
        <Title>Licenses</Title>
        {licenseAllocation?.licenses?.map(x => (
          <EditLicense
            key={x.type}
            loading={loading}
            userLicenses={user?.licenses ?? []}
            selectedLicenses={licenses}
            allocation={x}
            onToggle={() => toggleLicenses(x.type)}
          />
        ))}
        {licenseAllocation?.integrations?.some(x => x.total > 0) && (
          <>
            <Title>Features</Title>
            {licenseAllocation?.integrations?.map(x => (
              <EditFeature
                key={x.type}
                loading={loading}
                integration={x}
                selectedIntegrations={integrations}
                userIntegrations={user?.integrations ?? []}
                onToggle={() => toggleIntegrations(x.type)}
              />
            ))}
          </>
        )}

        {enterpriseAccount && (
          <div>
            <Title>Account Restrictions</Title>
            <Checkbox
              disabled={loading}
              size='small'
              onChange={setHideEmails}
              checked={hideEmails}
              helpText={`'The user will not see any ${getResearcherText()} emails when exporting data`}
              labelText='Cannot see emails'
            />
            <Checkbox
              disabled={loading}
              size='small'
              onChange={setDisableExports}
              checked={disableExports}
              helpText='The user will not be able to export data'
              style={{ paddingLeft: 50 }}
              labelText='Cannot export'
            />
          </div>
        )}
        {error && error?.status !== 412 && (
          <Alert type='error' message={error.message || defaultError(!!user)} />
        )}
      </Content>
    </AntModal>
  );
};

export default AddUser;
