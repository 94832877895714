import PropTypes from 'prop-types';
import { Modal as AntModal } from 'antd';

import BulkFooter from './BulkFooter';
import useBulkUnfollow from '../useBulkUnfollow';
import { getResearcherText } from 'utils/constants';

const BulkUnfollowModal = ({
  members,
  totalCount,
  onUnfollowSuccess,
  onUnfollowCancel,
}) => {
  const [handleUnfollow, loading, isResearcher] = useBulkUnfollow(
    onUnfollowSuccess
  );

  const handleCancel = () => {
    onUnfollowCancel();
  };

  if (!totalCount) return null;

  return (
    <AntModal
      open
      title={`Unfollow ${
        isResearcher ? getResearcherText({ capitalize: true }) : 'Organisation'
      }`}
      onCancel={handleCancel}
      footer={
        <BulkFooter
          onOk={() => handleUnfollow(members)}
          onCancel={handleCancel}
          loading={loading}
        />
      }
    >
      Are you sure you want to unfollow{' '}
      <span style={{ fontWeight: 'bold' }}>
        {totalCount}{' '}
        {isResearcher ? getResearcherText({ isPlural: true }) : 'organisations'}
      </span>
      ?
    </AntModal>
  );
};

BulkUnfollowModal.propTypes = {
  members: PropTypes.shape({
    all: PropTypes.bool.isRequired,
    include: PropTypes.shape({}),
    exclude: PropTypes.shape({}),
  }),
  totalCount: PropTypes.number.isRequired,
  onUnfollowSuccess: PropTypes.func.isRequired,
  onUnfollowCancel: PropTypes.func.isRequired,
};

export default BulkUnfollowModal;
