import { Tooltip } from 'antd';

import { Select } from 'components/Lists/Actions/styled';
import listRenderOption from 'components/common/Lists/ListRenderOptions';

const ListAction = props => {
  const selectLabel = 'Add All to List';

  const {
    disabled,
    onCreateNewItemList,
    onChangeAddAllToList,
    listOptions,
    fetchListLoading,
    blockedForExport,
  } = props;

  const options =
    listOptions && listOptions.length === 0
      ? [{ label: 'No lists available', value: 'no_lists' }]
      : listOptions;

  const handleSelectChange = value => {
    if (value === 'no_lists') {
      return;
    }
    onChangeAddAllToList(value);
  };

  return (
    <Tooltip
      placement='topRight'
      title={
        blockedForExport
          ? 'You need to update the search to reactivate add all to list'
          : ''
      }
    >
      <Select
        value={selectLabel}
        size='small'
        disabled={fetchListLoading || disabled}
        options={options}
        onChange={handleSelectChange}
        dropdownStyle={{ minWidth: '225px' }}
        style={{ marginTop: '2px', width: 125 }}
        onCreateNewItem={onCreateNewItemList}
        renderOption={(o, i) => listRenderOption({ ...o }, i)}
        buttonText='Create New List'
        placement='bottomRight'
        filterOption={(input, option) =>
          option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
        disableTooltip
      />
    </Tooltip>
  );
};

export default ListAction;
