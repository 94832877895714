import { Icon, Link } from 'components/common';
import { colours } from 'utils/theme';
import { Owners, OwnersBar } from './styled';
import { getResearcherText } from 'utils/constants';

import { Label } from './styled';

const OwnersDetails = ({ researchers, organisations }) => {
  return (
    <OwnersBar>
      <Owners>
        {' '}
        <Label>
          <Icon icon='user' color={colours.black} size='sm' />
          {getResearcherText({ isPlural: true, capitalize: true })}:
        </Label>{' '}
        {researchers.map(researcher => (
          <Link
            size='sm'
            key={`researcher-activity-${researcher.id}`}
            to={`/researcher/${researcher.id}`}
            showIconHint
          >
            {researcher.name}
          </Link>
        ))}
      </Owners>
      {organisations && !organisations.every(org => org.id === 0) && (
        <Owners>
          <Label>
            <Icon icon='company' color={colours.black} size='sm' />
            Organisations:
          </Label>
          {organisations.map(org => {
            if (org.id === 0) return null;
            return (
              <Link
                size='sm'
                key={`organisation-activity-${org.id}`}
                to={`/organisation/${org.id}`}
                showIconHint
              >
                {org.name}
              </Link>
            );
          })}
        </Owners>
      )}
    </OwnersBar>
  );
};

export default OwnersDetails;
