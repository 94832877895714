import { downloadType } from 'components/Export/Summary/constants';
import { Icon } from 'components/common';
import { getResearcherText } from 'utils/constants';

export const EXPORT_STATUS = {
  INPROGRESS: 0,
  SUCCESS: 1,
  FAILED: 2,
  EXPIRED: 3,
};

export const EXPORT_TYPE = {
  [downloadType.researcherSearch]: {
    value: downloadType.researcherSearch,
    label: `${getResearcherText({ capitalize: true })} Context`,
  },
  [downloadType.organisationSearch]: {
    value: downloadType.organisationSearch,
    label: 'Organisation Context',
  },
  [downloadType.researcherList]: {
    value: downloadType.researcherList,
    label: `${getResearcherText({ capitalize: true })} List`,
  },
  [downloadType.purchasingSearch]: {
    value: downloadType.purchasingSearch,
    label: 'Purchasing',
  },
  [downloadType.tradeshowSearch]: {
    value: downloadType.tradeshowSearch,
    label: 'Tradeshow Calendar',
  },
  [downloadType.network]: {
    value: downloadType.network,
    label: `${getResearcherText({ capitalize: true })} Network`,
  },
  [downloadType.organisationResearchersSearch]: {
    value: downloadType.organisationResearchersSearch,
    label: 'Organisation People',
  },
  [downloadType.organisationActivitySearch]: {
    value: downloadType.organisationActivitySearch,
    label: 'Organisation Activity',
  },
  [downloadType.organisationClinicalTrialsSearch]: {
    value: downloadType.organisationClinicalTrialsSearch,
    label: 'Organisation Clinical Trial',
  },
  [downloadType.crmPush]: { value: downloadType.crmPush, label: 'CRM Push' },
  [downloadType.organisationOnlyExport]: {
    value: downloadType.organisationOnlyExport,
    label: 'Organisation Only',
  },
  [downloadType.organisationList]: {
    value: downloadType.organisationList,
    label: 'Organisation List',
  },
};

export const StatusButtonConfig = {
  [EXPORT_STATUS.INPROGRESS]: {
    text: 'In Progress',
    background: '#fef9e9',
    color: '#f1c21b',
    tooltip:
      'Your export is currently in progress. Depending on the size of the data, this may take a few moments.',
  },
  [EXPORT_STATUS.SUCCESS]: {
    text: 'Success',
    background: '#eff8f9',
    color: '#56b3c3',
    tooltip: 'Your export is complete. Click download to access your file.',
  },
  [EXPORT_STATUS.FAILED]: {
    text: 'Failed',
    background: '#fce9ea',
    color: '#da1e28',
    tooltip:
      'Your export has failed. Please try again or contact customer success if the issue persists.',
  },
  [EXPORT_STATUS.EXPIRED]: {
    text: 'Expired',
    background: '#f4f4f4',
    color: '#8d8d8d',
    tooltip: 'Your export has expired. Please search again to get your data.',
    jsx: (
      <div>
        <Icon icon='circleInfo' size='1x' color='inherit' />
        {' Expired'}
      </div>
    ),
  },
};
