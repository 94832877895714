import React, { useCallback, useState, useEffect } from 'react';
import { researcherHighlights } from 'services/api';
import { Button, Popover, Typography } from 'antd';
import { HighlightSpan } from './HighlightSpan';
import { usePost } from 'hooks/xhr';
import { Loader } from 'components/common';
import { Container, List, ListItem, ContentWrapper } from './styled';
import styled from 'styled-components';
const { Text } = Typography;

/**
 * @typedef {import('./helpers').DocumentCategories} DocumentCategories
 *
 * @typedef {Object} HighlightPopoverProps
 * @property {React.RefObject<HTMLElement>} parentRef - Reference to the parent container element that will contain the popover
 * @property {string | number} entityId - Unique identifier for the entity being highlighted
 * @property {DocumentCategories} entityType - Type of entity being highlighted (e.g., 'publication', 'poster')
 * @property {string} highlightQuery - The search query to use for highlighting
 */

/**
 * A popover component that displays text matches from a search query.
 * The component shows a "Matches" button that, when clicked, reveals a popover
 * containing highlighted text snippets. Data is fetched lazily on first click.
 *
 * Features:
 * - Lazy loading of highlight data
 * - Animated transitions between states
 * - Deduplication of matching text
 * - Scrollable content for multiple matches
 *
 * @param {HighlightPopoverProps} props
 * @returns {React.ReactElement} Rendered component
 *
 * @example
 * <HighlightPopover
 *   parentRef={containerRef}
 *   entityId="123"
 *   entityType="researcher"
 *   highlightQuery="your search query"
 * />
 */

const ButtonLoader = styled(Loader)`
  .loader {
    font-size: 4px;
    margin: 0;
    top: 0;
    position: relative;
    transform: translateY(-10px);
  }
`;

const HighlightPopover = ({
  parentRef,
  entityId,
  entityType,
  highlightQuery,
}) => {
  const [parentWidth, setParentWidth] = useState(0);
  const [highlights, setHighlights] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [{ loading, error, res: data }, fetchHighlights] = usePost({
    url: researcherHighlights,
    body: {
      highlightQuery,
      entityId: String(entityId),
      entityType: String(entityType),
    },
  });

  useEffect(() => {
    const updateWidth = () => {
      if (parentRef?.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };
    updateWidth();
  }, [parentRef]);

  useEffect(() => {
    if (data) {
      setHighlights(data);
    }
  }, [data]);

  const getPopupContainer = useCallback(
    triggerNode => {
      if (parentRef.current) {
        return parentRef.current;
      }
      return triggerNode.parentNode;
    },
    [parentRef]
  );

  // Calculate the width for the List
  const listWidth = Math.max(100, parentWidth * 0.8);

  const content = () => {
    if (error) {
      return (
        <Container width={listWidth}>
          <ContentWrapper>Failed to load matches</ContentWrapper>
        </Container>
      );
    }

    let allStrings = [];
    let stringSet = new Set();

    if (!highlights || Object.keys(highlights).length === 0) {
      allStrings = ['Your search matched this activity'];
    } else {
      for (let key in highlights) {
        if (highlights.hasOwnProperty(key) && Array.isArray(highlights[key])) {
          highlights[key].forEach(value => {
            if (value !== 'Your search matched this activity.') {
              value = '...' + value + '...';
            }
            stringSet.add(value);
          });
        }
      }
      allStrings = Array.from(stringSet);

      // If we didn't find any valid highlights, use the default message
      if (allStrings.length === 0) {
        allStrings = ['Your search matched this activity'];
      }
    }

    return (
      <Container width={listWidth}>
        <ContentWrapper>
          <List
            size='small'
            dataSource={allStrings}
            renderItem={(highlight, index) => (
              <ListItem index={index}>
                <Text style={{ wordBreak: 'break-word' }}>
                  <HighlightSpan
                    dangerouslySetInnerHTML={{ __html: highlight }}
                  />
                </Text>
              </ListItem>
            )}
          />
        </ContentWrapper>
      </Container>
    );
  };

  return (
    <Popover
      getPopupContainer={getPopupContainer}
      content={content()}
      title='Search Matches'
      trigger='click'
      placement='topRight'
      open={isOpen && !!highlights}
      onOpenChange={visible => {
        if (visible && !highlights && !loading) {
          fetchHighlights();
        }
        setIsOpen(visible);
      }}
    >
      <Button
        size='small'
        type='secondary'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '70px',
          height: '24px',
        }}
      >
        {loading ? <ButtonLoader size='small' /> : 'Matches'}
      </Button>
    </Popover>
  );
};

export default HighlightPopover;
