import { Tooltip } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { Icon } from 'components/common';
import { useAuth, useDarkMode } from 'hooks';
import licenseType from 'models/licenseType';

import {
  AvatarContainer,
  StyledAvatar,
  Container,
  IconContainer,
  ThemedText,
} from './styled';
import { hasFullLicense } from 'utils/helpers/license';

export const Avatar = ({ initials }) => {
  return (
    <AvatarContainer>
      <StyledAvatar>{initials}</StyledAvatar>
    </AvatarContainer>
  );
};

const NavIcon = ({ icon, route, onClick, activeWith, text }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [darkMode] = useDarkMode();

  const isActive = activeWith
    ? pathname.includes(activeWith)
    : pathname === route;

  const handleClick = () => {
    return onClick ? onClick() : push(route);
  };

  return (
    <Tooltip title={text} placement='right'>
      <IconContainer
        onClick={handleClick}
        isActive={isActive}
        darkMode={darkMode}
      >
        <Icon icon={icon} size='lg' />
        <ThemedText isActive={isActive}>{text}</ThemedText>
      </IconContainer>
    </Tooltip>
  );
};

const Navigation = () => {
  const { logOut, softLogOut, user, hasLicense } = useAuth();
  const { userName } = user;
  const names = userName ? userName.split(' ') : 'No Name';
  const initials = [
    names[0].substring(0, 1),
    names[names.length - 1].substring(0, 1),
  ].join('');

  const [darkMode] = useDarkMode();
  const haslicenseToPurchasing = hasLicense(
    [licenseType.legacy, licenseType.academic],
    true
  );

  const handleLogout = () => {
    softLogOut();
    logOut();
  };
  return (
    <Container darkMode={darkMode}>
      <div>
        <Avatar initials={initials} />
        <NavIcon route='/' icon='users' text='Search' />
        <NavIcon route='/searches' icon='bell' text='Searches & Alerts' />
        <NavIcon route='/lists' icon='list' text='Lists' />
        <NavIcon route='/calendar' icon='calendar' text='Tradeshow Calendar' />
        {haslicenseToPurchasing && (
          <NavIcon
            route='/purchasing'
            icon='searchDollar'
            text='Purchasing Search'
          />
        )}
        {((!user.disableExport && hasFullLicense(user)) ||
          user.isUserManager) && (
          <NavIcon
            route='/exportActivity'
            icon='clockRotateLeft'
            text='Export Activity'
          />
        )}
        <NavIcon
          route='/settings'
          activeWith='settings'
          icon='settings'
          text='Settings'
        />
      </div>
      <div>
        {/* <NavIcon icon='chat' /> */}
        <NavIcon onClick={handleLogout} icon='signOut' />
      </div>
    </Container>
  );
};

export default Navigation;
