import { Fragment, useEffect, useMemo, useState } from 'react';
import useLog from 'hooks/useLog';
import { format } from 'utils/date';
import { Link, Pane, Table } from 'components/common';
import { emailRequests } from 'services/api';
import { useAuth, useGet } from 'hooks';
import licenseType from 'models/licenseType';
import { Status } from './Status';
import { getResearcherText } from 'utils/constants';

const EmailRequests = () => {
  const { hasLicense } = useAuth();
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(0);
  const [sort, setSort] = useState({ field: 'direction', direction: 'asc' });
  const [{ res, loading }, fetchEmailRequests] = useGet({ onMount: false });
  const canVisitOrgPage = useMemo(
    () => hasLicense([licenseType.industry], true),
    [hasLicense]
  );
  useLog('User clicked to Email Requests.', 'ViewEmailRequests', 'Success');

  const handleSortChange = sortKey => {
    setSort(sort => {
      const sortDir = !sort
        ? 'asc'
        : sortKey === !sort.field
        ? 'asc'
        : sort.direction === 'asc'
        ? 'desc'
        : 'asc';
      return { field: sortKey, direction: sortDir };
    });
  };

  useEffect(() => {
    fetchEmailRequests({
      url: emailRequests,
      query: {
        pageSize,
        pageNumber,
        orderBy: sort.field,
        order: sort.direction,
      },
    });
  }, [pageSize, pageNumber, sort, fetchEmailRequests]);

  const config = {
    headers: [
      { label: getResearcherText({ capitalize: true }), key: 'researcher' },
      { label: 'Organisation', key: 'organisation' },
      { label: 'Date Requested', key: 'date' },
      { label: 'Status', key: 'status' },
    ],
    createRow: (r, i) => (
      <Fragment key={i}>
        <div>
          {!r.profileOnly ? (
            <Link size='medium' to={`/researcher/${r.researcherId}`}>
              {r.researcherFirstName} {r.researcherLastName}
            </Link>
          ) : (
            <>
              {r.researcherFirstName} {r.researcherLastName}
            </>
          )}
        </div>
        <div>
          {r.organisationId > 0 && canVisitOrgPage ? (
            <Link size='medium' to={`/organisation/${r.organisationId}`}>
              {r.organisation}
            </Link>
          ) : (
            <>{r.organisation}</>
          )}
        </div>
        <div>{format(r.dateRequested)}</div>
        <div>
          <Status status={r.status} email={r.email} />
        </div>
      </Fragment>
    ),
  };
  return (
    <Pane title='Email Requests'>
      <Table
        style={{ marginTop: 15 }}
        config={config}
        data={res?.data ?? []}
        loading={loading}
        onChangePageSize={setPageSize}
        onChangePageNumber={setPageNumber}
        pageSize={pageSize}
        pageNumber={pageNumber}
        resultSize={res?.total ?? 0}
        onSortChange={handleSortChange}
        sort={sort?.field}
        sortDir={sort?.direction}
        paginate
        sortable
      />
    </Pane>
  );
};

export default EmailRequests;
