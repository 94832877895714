export const defaultError = 'Invalid UserName or Password';
const notActiveError = 'Account is not active';
const lockedError = 'User Account is still temporarily locked';
const notApprovedError = 'Account is not approved';
const noLicenseError = `Account does not have a license`;

export const invalidUserNamePasswordErrorMessage = (
  <span>
    Invalid UserName or Password. Please try again or{' '}
    <a href='/reset'>click here to Reset Password</a>
  </span>
);

export const errors = {
  'Invalid credentials.': 'Email Address or Password Incorrect',
  'Auth.TfaLocked': lockedError,
  'LicenseCheck.LicenseExpired': 'License has expired',
  'LicenseCheck.AccountNotActive': notActiveError,
};

export const getErrorForLog = loginError => {
  if (!loginError) return null;

  if (loginError.errors) {
    const errorType = Object.keys(loginError.errors)[0];
    if (errors[errorType]) {
      return errors[errorType];
    }
  }

  return loginError.message || defaultError;
};

export const errorTypeToMessage = {
  'LicenseCheck.LicenseExpired':
    'Your license has expired. Please contact customer support to speak about your account.',
  'Invalid credentials.': invalidUserNamePasswordErrorMessage,
  'Auth.InvalidGoogleToken':
    'Issue with external login. Please try again later.',
  // Add more mappings as needed
};

export const getErrorMessage = loginError => {
  if (!loginError) return null;

  // Check for specific error types in the errors object
  if (loginError.errors) {
    const errorType = Object.keys(loginError.errors)[0];
    if (errorTypeToMessage[errorType]) {
      return errorTypeToMessage[errorType];
    }
  }

  // Fall back to message field if it exists in mapping
  if (loginError.message && errorTypeToMessage[loginError.message]) {
    return errorTypeToMessage[loginError.message];
  }

  // Default to the error message or default error
  return loginError.message || defaultError;
};

export const getPreConditionError = loginError => {
  if (loginError.message.includes('Account Inactive')) {
    return notActiveError;
  }

  if (loginError.message.includes('locked')) {
    return lockedError;
  }

  if (loginError.message.includes('Your account has not yet been approved')) {
    return notApprovedError;
  }

  if (loginError.message.includes('No License')) {
    return noLicenseError;
  }

  return defaultError;
};
