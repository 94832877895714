import { HistorySelect } from 'components/ExportHistory/styled';
import { tagRender } from 'components/common/TagRender';
import { useGet } from 'hooks';
import { useEffect } from 'react';
import { allUserAccounts } from 'services/api';
import { arraysEqualUnordered } from 'utils/array';

const UserFilter = ({ onChange, userIds }) => {
  function selectUsers(values) {
    onChange(values || []);
  }

  const [{ res: allUsers }] = useGet({
    url: allUserAccounts,
    onMount: true,
  });

  useEffect(() => {
    if (allUsers?.length) {
      let idsThatsExist = userIds.filter(id =>
        allUsers.some(u => u.userId === id)
      );

      if (!arraysEqualUnordered(idsThatsExist, userIds)) {
        onChange(idsThatsExist);
      }
    }
    //eslint-disable-next-line
  }, [allUsers]);

  const mappedUsers = allUsers?.map(item => ({
    label: item.fullname,
    value: item.userId,
  }));

  return (
    <HistorySelect
      mode={'multiple'}
      placeholder='Users'
      showSearch={true}
      options={mappedUsers}
      onChange={selectUsers}
      tagRender={tagRender}
      remoteSearchOnClick
      allowClear={true}
      value={
        (mappedUsers &&
          userIds
            ?.filter(id => mappedUsers.some(u => u.value === id))
            ?.map(x => ({ value: x }))) ||
        []
      }
    />
  );
};

export default UserFilter;
