import storage from 'services/storage';

export const types = {
  SET_SIGNALR_EXPORT_REFRESH: 'SET_SIGNALR_EXPORT_REFRESH',
  RESET_SIGNALR_EXPORT_REFRESH: 'RESET_SIGNALR_EXPORT_REFRESH',
  SET_EXPORT_HISTORY_FILTERS: 'SET_EXPORT_HISTORY_FILTERS',
  SET_DATE_OPTION: 'SET_EXPORT_HISTORY_DATE_OPTION',
  RESTORE_STATE: 'RESTORE_EXPORT_HISTORY_STATE',
};

const persistState = async getState => {
  const state = getState();
  const toSave = JSON.parse(JSON.stringify(state)).exportHistory;
  await storage.setItem('exportHistory', toSave);
};

export const setSignalRExportUpdate = id => ({
  type: types.SET_SIGNALR_EXPORT_REFRESH,
  payload: { id },
});

export const resetSignalRExportRefresh = () => ({
  type: types.RESET_SIGNALR_EXPORT_REFRESH,
});

export const setFilters = (userId, value) => (dispatch, getState) => {
  dispatch({
    type: types.SET_EXPORT_HISTORY_FILTERS,
    payload: { userId, value },
  });
  persistState(getState);
};

export const setDateOption = (userId, value) => (dispatch, getState) => {
  dispatch({
    type: types.SET_DATE_OPTION,
    payload: { userId, value },
  });
  persistState(getState);
};

export const restoreState = state => ({
  type: types.RESTORE_STATE,
  payload: state,
});
